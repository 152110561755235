<script setup>
import { defineEmits, defineProps, inject, ref, reactive, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useEnvStore } from "@dutypay/store-modules";
import { interceptor } from "@dutypay/utilities";
import { to } from 'await-to-js';

const { t } = useI18n();
const composables = inject("composables");
const emit = defineEmits(["update", "trigger-authenticate"]);
const props = defineProps({
  modelValue: { type: Object, required: true },
});
const loginData = composables.useTwoWayBinding(props, emit, "modelValue");
const secretQrCode = ref('');
const secretText = ref('');
const isLoading = ref(false);
const notification = reactive({
  fetchSecret: {
    asyncError: false
  }
});

async function fetchSecret() {
  const envStore = useEnvStore();
  notification.fetchSecret.asyncError = false;
  isLoading.value = true;
  let err, qrCode;
  [err, qrCode] = await to(interceptor.post(`${ envStore.apiUrls.dutypay }/api/v0/generate/secret`, { ...loginData.value }));
  if (err) {
    isLoading.value = false;
    notification.fetchSecret.asyncError = true;
  }
  secretQrCode.value = qrCode.data.qr;
  secretText.value = qrCode.data.plaintext;
  isLoading.value = false;
}

onMounted(async () => {
  await fetchSecret();
})

function setupAuthenticator() {
  loginData.value.firstTime = true;
  loginData.value.mode = "authenticator";
  emit("trigger-authenticate");
}
</script>
<script>
export default {
  name: "AuthenticatorSetupForm"
};
</script>

<template>
  <div>
    <h2>{{ t("authenticatorSetupForm.title") }}</h2>

    <DpForm
      v-model="loginData"
      hide-required-asterisk
      reference="setup-authenticator"
      :action="setupAuthenticator"
      v-loading="isLoading"
      :primaryButtonLabel="t('confirmCode.buttonLabel')"
      :primaryButtonParameter="{
        class: ['full-width'],
        size: 'large',
      }"
      disable-notification>

      <p>{{ t("scanCode.label") }}</p>

      <DpExpand>
        <div v-show="!!secretQrCode" class="text-align-center"
             style="margin: 1em auto; width: 228px">
          <img :src="secretQrCode" height="228" width="228" alt="QR code"/>
          <DpCopyToClipboard :value="secretText">
            <span class="monospace authenticator-setup-code">{{ secretText }}</span>
          </DpCopyToClipboard>
        </div>
      </DpExpand>

      <DpNotification
        type="error"
        v-show="notification.fetchSecret.asyncError"
        :title="t('notification.fetchSecret.asyncError.title')"
        :message="t('notification.fetchSecret.asyncError.message')">
        <template slot="controls">
          <dp-button purpose="secondaryAction" @click="fetchSecret">{{
              t("tryAgain.buttonLabel")
            }}
          </dp-button>
        </template>
      </DpNotification>

      <p>{{ t("afterYouScannedCode.label") }}</p>

      <DpInputMultiFactorAuthenticationCode
        v-model="loginData.authenticatorOTP"
        prop="authenticatorOTP"
        required
        :input-parameters="{ size: 'large' }"
      />
    </DpForm>
  </div>
</template>

<style scoped lang="scss">
.authenticator-setup-code {
  margin: 1em 0;
  letter-spacing: 1px;
  word-break: break-all;
}
</style>

<i18n>
{
  "ar-AE": {
    "scanCode": {
      "label": "امسح رمز الاستجابة السريعة هذا ضوئيًا أو استخدم الرمز أدناه لتوصيل تطبيق المصادقة الخاص بك."
    },
    "afterYouScannedCode": {
      "label": "قم بتنشيط المصادقة ذات العاملين عن طريق إدخال رمز المصادقة وتأكيده. يتم إنشاء الرمز بواسطة تطبيق المصادقة."
    },
    "authenticatorSetupForm": {
      "title": "تطبيق المصادقة"
    },
    "confirmCode": {
      "buttonLabel": "تأكيد"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "خطأ",
          "message": "لا يمكن تأكيد رمز المصادقة."
        }
      }
    }
  },
  "ar-EG": {
    "scanCode": {
      "label": "امسح رمز الاستجابة السريعة هذا ضوئيًا أو استخدم الرمز أدناه لتوصيل تطبيق المصادقة الخاص بك."
    },
    "afterYouScannedCode": {
      "label": "قم بتنشيط المصادقة ذات العاملين عن طريق إدخال رمز المصادقة وتأكيده. يتم إنشاء الرمز بواسطة تطبيق المصادقة."
    },
    "authenticatorSetupForm": {
      "title": "تطبيق المصادقة"
    },
    "confirmCode": {
      "buttonLabel": "تأكيد"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "خطأ",
          "message": "لا يمكن تأكيد رمز المصادقة."
        }
      }
    }
  },
  "ar-SA": {
    "scanCode": {
      "label": "امسح رمز الاستجابة السريعة هذا ضوئيًا أو استخدم الرمز أدناه لتوصيل تطبيق المصادقة الخاص بك."
    },
    "afterYouScannedCode": {
      "label": "قم بتنشيط المصادقة ذات العاملين عن طريق إدخال رمز المصادقة وتأكيده. يتم إنشاء الرمز بواسطة تطبيق المصادقة."
    },
    "authenticatorSetupForm": {
      "title": "تطبيق المصادقة"
    },
    "confirmCode": {
      "buttonLabel": "تأكيد"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "خطأ",
          "message": "لا يمكن تأكيد رمز المصادقة."
        }
      }
    }
  },
  "el-GR": {
    "scanCode": {
      "label": "Σαρώστε αυτόν τον κωδικό QR ή χρησιμοποιήστε τον παρακάτω κωδικό για να συνδέσετε την εφαρμογή ελέγχου ταυτότητας."
    },
    "afterYouScannedCode": {
      "label": "Ενεργοποιήστε τον έλεγχο ταυτότητας δύο παραγόντων εισάγοντας και επιβεβαιώνοντας τον κωδικό ελέγχου ταυτότητας. Ο κώδικας δημιουργείται από την εφαρμογή ελέγχου ταυτότητας."
    },
    "authenticatorSetupForm": {
      "title": "Εφαρμογή ελέγχου ταυτότητας"
    },
    "confirmCode": {
      "buttonLabel": "Επιβεβαίωση"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Σφάλμα",
          "message": "Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας."
        }
      }
    }
  },
  "el-CY": {
    "scanCode": {
      "label": "Σαρώστε αυτόν τον κωδικό QR ή χρησιμοποιήστε τον παρακάτω κωδικό για να συνδέσετε την εφαρμογή ελέγχου ταυτότητας."
    },
    "afterYouScannedCode": {
      "label": "Ενεργοποιήστε τον έλεγχο ταυτότητας δύο παραγόντων εισάγοντας και επιβεβαιώνοντας τον κωδικό ελέγχου ταυτότητας. Ο κώδικας δημιουργείται από την εφαρμογή ελέγχου ταυτότητας."
    },
    "authenticatorSetupForm": {
      "title": "Εφαρμογή ελέγχου ταυτότητας"
    },
    "confirmCode": {
      "buttonLabel": "Επιβεβαίωση"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Σφάλμα",
          "message": "Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας."
        }
      }
    }
  },
  "de-DE": {
    "scanCode": {
      "label": "Scanne diesen QR-Code oder verwende den darunter stehenden Code, um deine Authentifizierungs-App zu verbinden."
    },
    "afterYouScannedCode": {
      "label": "Aktiviere die Zwei-Faktor-Authentifizierung durch Eingabe und Bestätigung des Authentifizierungscodes. Der Code wird von der Authentifizierungs-App generiert."
    },
    "authenticatorSetupForm": {
      "title": "Authentifizierungs-App"
    },
    "confirmCode": {
      "buttonLabel": "Bestätigen"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Fehler",
          "message": "Der Authentifizierungscode konnte nicht bestätigt werden."
        }
      }
    }
  },
  "de-AT": {
    "scanCode": {
      "label": "Scanne diesen QR-Code oder verwende den darunter stehenden Code, um deine Authentifizierungs-App zu verbinden."
    },
    "afterYouScannedCode": {
      "label": "Aktiviere die Zwei-Faktor-Authentifizierung durch Eingabe und Bestätigung des Authentifizierungscodes. Der Code wird von der Authentifizierungs-App generiert."
    },
    "authenticatorSetupForm": {
      "title": "Authentifizierungs-App"
    },
    "confirmCode": {
      "buttonLabel": "Bestätigen"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Fehler",
          "message": "Der Authentifizierungscode konnte nicht bestätigt werden."
        }
      }
    }
  },
  "de-CH": {
    "scanCode": {
      "label": "Scanne diesen QR-Code oder verwende den darunter stehenden Code, um deine Authentifizierungs-App zu verbinden."
    },
    "afterYouScannedCode": {
      "label": "Aktiviere die Zwei-Faktor-Authentifizierung durch Eingabe und Bestätigung des Authentifizierungscodes. Der Code wird von der Authentifizierungs-App generiert."
    },
    "authenticatorSetupForm": {
      "title": "Authentifizierungs-App"
    },
    "confirmCode": {
      "buttonLabel": "Bestätigen"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Fehler",
          "message": "Der Authentifizierungscode konnte nicht bestätigt werden."
        }
      }
    }
  },
  "en-US": {
    "scanCode": {
      "label": "Scan this QR code or use the code below to connect your authenticator app."
    },
    "afterYouScannedCode": {
      "label": "Activate two-factor authentication by entering and confirming the authentication code. The code is generated by the authentication app."
    },
    "authenticatorSetupForm": {
      "title": "Authenticator-App"
    },
    "confirmCode": {
      "buttonLabel": "Confirm"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Error",
          "message": "The authentication code could not be confirmed."
        }
      }
    }
  },
  "en-GB": {
    "scanCode": {
      "label": "Scan this QR code or use the code below to connect your authenticator app."
    },
    "afterYouScannedCode": {
      "label": "Activate two-factor authentication by entering and confirming the authentication code. The code is generated by the authentication app."
    },
    "authenticatorSetupForm": {
      "title": "Authenticator-App"
    },
    "confirmCode": {
      "buttonLabel": "Confirm"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Error",
          "message": "The authentication code could not be confirmed."
        }
      }
    }
  },
  "it-IT": {
    "scanCode": {
      "label": "Effettua la scansione di questo codice QR o usa il codice di seguito per connetterti alla tua app di autenticazione."
    },
    "afterYouScannedCode": {
      "label": "Attiva l'autentificazione a due fattori inserendo e confermando il codice di autenticazione."
    },
    "authenticatorSetupForm": {
      "title": "Authenticator-App"
    },
    "confirmCode": {
      "buttonLabel": "Conferma"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Errore",
          "message": "Non è stato possibile confermare il codice di autenticazione."
        }
      }
    }
  },
  "fr-FR": {
    "scanCode": {
      "label": "Scannez ce code QR ou utilisez le code ci-dessous pour connecter votre application d'authentification."
    },
    "afterYouScannedCode": {
      "label": "Activez l'authentification à deux facteurs en saisissant et en confirmant le code d'authentification. Ce code est généré par l'application d'authentification."
    },
    "authenticatorSetupForm": {
      "title": "Authentificateur-App"
    },
    "confirmCode": {
      "buttonLabel": "Confirmer"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Erreur",
          "message": "Le code d'authentification n'a pas pu être confirmé."
        }
      }
    }
  },
  "es-ES": {
    "scanCode": {
      "label": "Escanee este código QR o use el código a continuación para conectar su aplicación de autenticación."
    },
    "afterYouScannedCode": {
      "label": "Active la autenticación en dos pasos introduciendo y confirmando el código de autenticación. El código lo genera automáticamente la aplicación."
    },
    "authenticatorSetupForm": {
      "title": "Aplicación de autenticación"
    },
    "confirmCode": {
      "buttonLabel": "Confirmar"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Error",
          "message": "No se pudo confirmar el código de autenticación."
        }
      }
    }
  },
  "pl-PL": {
    "scanCode": {
      "label": "Zeskanuj ten kod QR lub użyj poniższego kodu, aby połączyć aplikację uwierzytelniającą."
    },
    "afterYouScannedCode": {
      "label": "Aktywuj uwierzytelnianie dwuskładnikowe, wprowadzając i potwierdzając kod uwierzytelniający. Kod jest generowany przez aplikację uwierzytelniającą."
    },
    "authenticatorSetupForm": {
      "title": "Aplikacja Authenticator"
    },
    "confirmCode": {
      "buttonLabel": "Potwierdź"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Błąd",
          "message": "Nie udało się potwierdzić kodu uwierzytelniającego."
        }
      }
    }
  },
  "nl-NL": {
    "scanCode": {
      "label": "Scan deze QR code of gebruik de onderstaande code om uw authenticatie app te verbinden."
    },
    "afterYouScannedCode": {
      "label": "Activeer de twee-factor authenticatie door de authenticatiecode in te voeren en te bevestigen. De code wordt gegenereerd door de verificatie-app."
    },
    "authenticatorSetupForm": {
      "title": "Authenticator-App"
    },
    "confirmCode": {
      "buttonLabel": "Bevestigen"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Fout",
          "message": "De authencitatiecode kon niet worden bevestigd."
        }
      }
    }
  },
  "bg-BG": {
    "scanCode": {
      "label": "Сканирайте този QR код или използвайте кода по-долу, за да свържете приложението си за удостоверяване."
    },
    "afterYouScannedCode": {
      "label": "Активирайте двуфакторно удостоверяване, като въведете и потвърдите кода за удостоверяване. Кодът се генерира от приложението за удостоверяване."
    },
    "authenticatorSetupForm": {
      "title": "Приложение за удостоверяване"
    },
    "confirmCode": {
      "buttonLabel": "Потвърждение"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "грешка",
          "message": "Кодът за удостоверяване не може да бъде потвърден."
        }
      }
    }
  },
  "bs-BA": {
    "scanCode": {
      "label": "Skenirajte ovaj QR kôd ili upotrijebite donji kôd za povezivanje aplikacije za autentifikaciju."
    },
    "afterYouScannedCode": {
      "label": "Aktivirajte dvofaktorsku autentifikaciju unosom i potvrđivanjem autentifikacijskog koda. Kôd generira aplikacija za autentifikaciju."
    },
    "authenticatorSetupForm": {
      "title": "Aplikacija za autentifikaciju"
    },
    "confirmCode": {
      "buttonLabel": "Potvrdite"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Greška",
          "message": "Nije moguće potvrditi autentifikacijski kôd."
        }
      }
    }
  },
  "da-DK": {
    "scanCode": {
      "label": "Scan denne QR-kode, eller brug koden nedenfor til at forbinde din godkendelsesapp."
    },
    "afterYouScannedCode": {
      "label": "Aktiver tofaktorautentificering ved at indtaste og bekræfte godkendelseskoden. Koden genereres af godkendelsesappen."
    },
    "authenticatorSetupForm": {
      "title": "Authenticator-App"
    },
    "confirmCode": {
      "buttonLabel": "Bekræft"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Fejl",
          "message": "Godkendelseskoden kunne ikke bekræftes."
        }
      }
    }
  },
  "et-EE": {
    "scanCode": {
      "label": "Skannige see QR-kood või kasutage oma autentimisrakenduse ühendamiseks allolevat koodi."
    },
    "afterYouScannedCode": {
      "label": "Aktiveerige kaheastmeline autentimine, sisestades ja kinnitades autentimiskoodi. Koodi genereerib autentimisrakendus."
    },
    "authenticatorSetupForm": {
      "title": "Authenticator-App"
    },
    "confirmCode": {
      "buttonLabel": "Kinnita"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Viga",
          "message": "Autentimiskoodi ei õnnestunud kinnitada."
        }
      }
    }
  },
  "fi-FI": {
    "scanCode": {
      "label": "Skannaa tämä QR-koodi tai käytä alla olevaa koodia yhdistääksesi todennussovelluksesi."
    },
    "afterYouScannedCode": {
      "label": "Aktivoi kaksivaiheinen todennus syöttämällä ja vahvistamalla todennuskoodi. Koodi on luotu todennussovelluksella."
    },
    "authenticatorSetupForm": {
      "title": "Authenticator-Sovellus"
    },
    "confirmCode": {
      "buttonLabel": "Vahvista"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Virhe",
          "message": "Todennuskoodia ei voitu vahvistaa."
        }
      }
    }
  },
  "he-IL": {
    "scanCode": {
      "label": "סרוק קוד QR זה או השתמש בקוד שלמטה כדי לחבר את אפליקציית האימות שלך."
    },
    "afterYouScannedCode": {
      "label": "הפעל אימות דו-גורמי על ידי הזנת קוד האימות ואישורו. הקוד נוצר על ידי אפליקציית האימות."
    },
    "authenticatorSetupForm": {
      "title": "מאמת-אפליקציה"
    },
    "confirmCode": {
      "buttonLabel": "לְאַשֵׁר"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "שְׁגִיאָה",
          "message": "לא ניתן היה לאשר את קוד האימות."
        }
      }
    }
  },
  "ga-IE": {
    "scanCode": {
      "label": "Scan an cód QR seo nó bain úsáid as an gcód thíos chun d’aip fíordheimhnithe a nascadh."
    },
    "afterYouScannedCode": {
      "label": "Fíordheimhniú dhá fhachtóir a ghníomhachtú tríd an gcód fíordheimhnithe a iontráil agus a dhearbhú. Gineann an aip fíordheimhnithe an cód."
    },
    "authenticatorSetupForm": {
      "title": "Aip fíordheimhnitheora"
    },
    "confirmCode": {
      "buttonLabel": "Deimhnigh"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Earráid",
          "message": "Níorbh fhéidir an cód fíordheimhnithe a dhearbhú."
        }
      }
    }
  },
  "ja-JP": {
    "scanCode": {
      "label": "この QR コードをスキャンするか、以下のコードを使用して認証アプリに接続します。"
    },
    "afterYouScannedCode": {
      "label": "認証コードを入力して確認することで、二要素認証を有効にします。 コードは認証アプリによって生成されます。"
    },
    "authenticatorSetupForm": {
      "title": "認証アプリ"
    },
    "confirmCode": {
      "buttonLabel": "確認"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "エラー",
          "message": "認証コードが確認できませんでした。"
        }
      }
    }
  },
  "hr-HR": {
    "scanCode": {
      "label": "Skenirajte ovaj QR kôd ili upotrijebite donji kod za povezivanje aplikacije za provjeru autentičnosti."
    },
    "afterYouScannedCode": {
      "label": "Aktivirajte dvofaktorsku autentifikaciju unosom i potvrđivanjem autentifikacijskog koda. Kôd generira aplikacija za provjeru autentičnosti."
    },
    "authenticatorSetupForm": {
      "title": "Autentifikator-App"
    },
    "confirmCode": {
      "buttonLabel": "Potvrda"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Pogreška",
          "message": "Nije moguće potvrditi autentifikacijski kod."
        }
      }
    }
  },
  "lv-LV": {
    "scanCode": {
      "label": "Skenējiet šo QR kodu vai izmantojiet zemāk esošo kodu, lai izveidotu savienojumu ar savu autentifikācijas lietotni."
    },
    "afterYouScannedCode": {
      "label": "Aktivizējiet divfaktoru autentifikāciju, ievadot un apstiprinot autentifikācijas kodu. Kodu ģenerē autentifikācijas lietotne."
    },
    "authenticatorSetupForm": {
      "title": "Autentifikators-lietotne"
    },
    "confirmCode": {
      "buttonLabel": "Apstiprināt"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Kļūda",
          "message": "Nevarēja apstiprināt autentifikācijas kodu."
        }
      }
    }
  },
  "lt-LT": {
    "scanCode": {
      "label": "Nuskaitykite šį QR kodą arba naudokite toliau pateiktą kodą, kad susietumėte savo autentifikavimo programą."
    },
    "afterYouScannedCode": {
      "label": "Suaktyvinkite dviejų veiksnių autentifikavimą įvesdami ir patvirtindami autentifikavimo kodą. Kodą sugeneruoja autentifikavimo programa."
    },
    "authenticatorSetupForm": {
      "title": "„Authenticator“ programa"
    },
    "confirmCode": {
      "buttonLabel": "Patvirtinti"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Klaida",
          "message": "Nepavyko patvirtinti autentifikavimo kodo."
        }
      }
    }
  },
  "pt-PT": {
    "scanCode": {
      "label": "Leia este código QR ou use o código abaixo para ligar a aplicação de autenticação."
    },
    "afterYouScannedCode": {
      "label": "Ative a autenticação de dois fatores inserindo e confirmando o código de autenticação. O código é gerado pela aplicação de autenticação."
    },
    "authenticatorSetupForm": {
      "title": "Authenticator-App"
    },
    "confirmCode": {
      "buttonLabel": "Confirmar"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Erro",
          "message": "Não foi possível confirmar o código de autenticação."
        }
      }
    }
  },
  "ro-RO": {
    "scanCode": {
      "label": "Scanați acest cod QR sau utilizați codul de mai jos pentru a vă conecta aplicația de autentificare."
    },
    "afterYouScannedCode": {
      "label": "Activați autentificarea cu doi factori introducând și confirmând codul de autentificare. Codul este generat de aplicația de autentificare."
    },
    "authenticatorSetupForm": {
      "title": "Aplicație de autentificare"
    },
    "confirmCode": {
      "buttonLabel": "Confirmare"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Eroare",
          "message": "Codul de autentificare nu a putut fi confirmat."
        }
      }
    }
  },
  "ru-RU": {
    "scanCode": {
      "label": "Отсканируйте этот QR-код или используйте приведенный ниже код для подключения приложения для аутентификации."
    },
    "afterYouScannedCode": {
      "label": "Активируйте двухфакторную аутентификацию, введя и подтвердив код аутентификации. Код генерируется приложением для аутентификации."
    },
    "authenticatorSetupForm": {
      "title": "Приложение для аутентификации"
    },
    "confirmCode": {
      "buttonLabel": "Подтвердить"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Ошибка",
          "message": "Код аутентификации не подтвержден."
        }
      }
    }
  },
  "sv-SE": {
    "scanCode": {
      "label": "Skanna den här QR-koden eller använd koden nedan för att ansluta din autentiseringsapp."
    },
    "afterYouScannedCode": {
      "label": "Aktivera tvåfaktorautentisering genom att ange och bekräfta autentiseringskoden. Koden genereras av autentiseringsappen."
    },
    "authenticatorSetupForm": {
      "title": "Autentiseringsapp"
    },
    "confirmCode": {
      "buttonLabel": "Bekräfta"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Fel",
          "message": "Autentiseringskoden kunde inte bekräftas."
        }
      }
    }
  },
  "sk-SK": {
    "scanCode": {
      "label": "Naskenujte tento QR kód alebo pomocou kódu nižšie pripojte vašu overovaciu aplikáciu."
    },
    "afterYouScannedCode": {
      "label": "Aktivujte dvojfaktorové overenie zadaním a potvrdením overovacieho kódu. Kód je vygenerovaný overovacou aplikáciou."
    },
    "authenticatorSetupForm": {
      "title": "Aplikácia Authenticator"
    },
    "confirmCode": {
      "buttonLabel": "Potvrdiť"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Chyba",
          "message": "Overovací kód nebolo možné potvrdiť."
        }
      }
    }
  },
  "cs-CZ": {
    "scanCode": {
      "label": "Naskenujte tento QR kód nebo pomocí níže uvedeného kódu připojte svoji ověřovací aplikaci."
    },
    "afterYouScannedCode": {
      "label": "Aktivujte dvoufaktorové ověřování zadáním a potvrzením ověřovacího kódu. Kód je generován ověřovací aplikací."
    },
    "authenticatorSetupForm": {
      "title": "Aplikace Authenticator"
    },
    "confirmCode": {
      "buttonLabel": "Potvrdit"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Chyba",
          "message": "Nelze potvrdit ověřovací kód."
        }
      }
    }
  },
  "hu-HU": {
    "scanCode": {
      "label": "Szkennelje be a QR-kódot, vagy használja az alábbi kódot a hitelesítési alkalmazás csatlakoztatásához."
    },
    "afterYouScannedCode": {
      "label": "Aktiválja a kétfaktoros hitelesítést a hitelesítő kód megadásával és megerősítésével."
    },
    "authenticatorSetupForm": {
      "title": "Hitelesítő app"
    },
    "confirmCode": {
      "buttonLabel": "Megerősít"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Hiba",
          "message": "A hitelesítő kódot nem sikerült megerősíteni."
        }
      }
    }
  },
  "fr-BE": {
    "scanCode": {
      "label": "Scannez ce code QR ou utilisez le code ci-dessous pour connecter votre application d'authentification."
    },
    "afterYouScannedCode": {
      "label": "Activez l'authentification à deux facteurs en saisissant et en confirmant le code d'authentification. Ce code est généré par l'application d'authentification."
    },
    "authenticatorSetupForm": {
      "title": "Authentificateur-App"
    },
    "confirmCode": {
      "buttonLabel": "Confirmer"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Erreur",
          "message": "Le code d'authentification n'a pas pu être confirmé."
        }
      }
    }
  },
  "fr-LU": {
    "scanCode": {
      "label": "Scannez ce code QR ou utilisez le code ci-dessous pour connecter votre application d'authentification."
    },
    "afterYouScannedCode": {
      "label": "Activez l'authentification à deux facteurs en saisissant et en confirmant le code d'authentification. Ce code est généré par l'application d'authentification."
    },
    "authenticatorSetupForm": {
      "title": "Authentificateur-App"
    },
    "confirmCode": {
      "buttonLabel": "Confirmer"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Erreur",
          "message": "Le code d'authentification n'a pas pu être confirmé."
        }
      }
    }
  },
  "mt-MT": {
    "scanCode": {
      "label": "Skennja dan il-kodiċi QR jew uża l-kodiċi hawn taħt biex tikkonnettja l-app ta' awtentikazzjoni tiegħek."
    },
    "afterYouScannedCode": {
      "label": "Attiva awtentikazzjoni b'żewġ fatturi billi ddaħħal u tikkonferma l-kodiċi ta' awtentikazzjoni."
    },
    "authenticatorSetupForm": {
      "title": "App tal-Awtentikatur"
    },
    "confirmCode": {
      "buttonLabel": "Ikkonferma"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Żball",
          "message": "Il-kodiċi ta' awtentikazzjoni ma setax jiġi kkonfermat."
        }
      }
    }
  },
  "sl-SI": {
    "scanCode": {
      "label": "Optično preberite to kodo QR ali uporabite spodnjo kodo za povezavo aplikacije za preverjanje pristnosti."
    },
    "afterYouScannedCode": {
      "label": "Dvofaktorsko preverjanje pristnosti aktivirajte tako, da vnesete in potrdite kodo za preverjanje pristnosti. Kodo generira aplikacija za preverjanje pristnosti."
    },
    "authenticatorSetupForm": {
      "title": "Aplikacija za preverjanje pristnosti"
    },
    "confirmCode": {
      "buttonLabel": "Potrdi"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Napaka",
          "message": "Kode za preverjanje pristnosti ni bilo mogoče potrditi."
        }
      }
    }
  },
  "tr-TR": {
    "scanCode": {
      "label": "Kimlik doğrulama uygulamanıza bağlanmak için bu QR kodunu taratın veya aşağıdaki kodu kullanın."
    },
    "afterYouScannedCode": {
      "label": "Kimlik doğrulama kodunu girip onaylayarak iki faktörlü kimlik doğrulamayı etkinleştirin. Kod, kimlik doğrulama uygulaması tarafından oluşturulur."
    },
    "authenticatorSetupForm": {
      "title": "Kimlik Doğrulayıcı-Uygulama"
    },
    "confirmCode": {
      "buttonLabel": "Onayla"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Hata",
          "message": "Kimlik doğrulama kodu onaylanamadı."
        }
      }
    }
  },
  "tr-CY": {
    "scanCode": {
      "label": "Kimlik doğrulama uygulamanıza bağlanmak için bu QR kodunu taratın veya aşağıdaki kodu kullanın."
    },
    "afterYouScannedCode": {
      "label": "Kimlik doğrulama kodunu girip onaylayarak iki faktörlü kimlik doğrulamayı etkinleştirin. Kod, kimlik doğrulama uygulaması tarafından oluşturulur."
    },
    "authenticatorSetupForm": {
      "title": "Kimlik Doğrulayıcı-Uygulama"
    },
    "confirmCode": {
      "buttonLabel": "Onayla"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Hata",
          "message": "Kimlik doğrulama kodu onaylanamadı."
        }
      }
    }
  },
  "uk-UA": {
    "scanCode": {
      "label": "Scanne diesen QR-Code oder verwende den darunter stehenden Code, um deine Authentifizierungs-App zu verbinden."
    },
    "afterYouScannedCode": {
      "label": "Aktiviere die Zwei-Faktor-Authentifizierung durch Eingabe und Bestätigung des Authentifizierungscodes. Der Code wird von der Authentifizierungs-App generiert."
    },
    "authenticatorSetupForm": {
      "title": "Authentifizierungs-App"
    },
    "confirmCode": {
      "buttonLabel": "Підтвердити"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "Помилка",
          "message": "Не вдалося підтвердити код автентифікації."
        }
      }
    }
  },
  "zh-CN": {
    "scanCode": {
      "label": "扫描此二维码或使用以下代码连接您的身份验证应用程序。"
    },
    "afterYouScannedCode": {
      "label": "输入并确认身份验证码以激活双重身份验证。该代码由身份验证应用程序生成。"
    },
    "authenticatorSetupForm": {
      "title": "身份验证器应用"
    },
    "confirmCode": {
      "buttonLabel": "确认"
    },
    "notification": {
      "fetchSecret": {
        "asyncError": {
          "title": "错误",
          "message": "无法确认验证码。"
        }
      }
    }
  }
}
</i18n>
