<script setup>
import PageContainer from "../components/PageContainer";
//Stage 1: Component that accepts Email address to identify user
import EmailForm from "./LoginStages/EmailForm";
//Stage 2: Component that accepts Email OTP and sends Auth Code E-Mail
import EmailOTPForm from "./LoginStages/EmailOTPForm";
//Stage 3: Display Option to setup or enter MFA
import MFAChoice from "./LoginStages/MFAChoice";
//Stage 4a: Setup Authenticator MFA
import AuthenticatorSetupForm from "./LoginStages/AuthenticatorSetupForm";
//Stage 5a: Component that accepts Authenticator OTP
import AuthenticatorOTPForm from "./LoginStages/AuthenticatorOTPForm";
//Stage 4b: Setup SMS MFA
import SMSSetupForm from "./LoginStages/SMSSetupForm";
//Stage 5b: Component that accepts SMS OTP anbd sends Auth Code SMS
import SMSOTPForm from "./LoginStages/SMSOTPForm";

import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from 'vue-router';
import { reactive, ref, computed, onMounted, inject } from 'vue';
import axios from "axios";
import { to } from "await-to-js";
import { get } from "lodash";
import { useEnvStore } from "@dutypay/store-modules";

const { t } = useI18n();
const { locale } = useI18n({useScope: 'global'})
const router = useRouter();
const route = useRoute();
const currentComponent = ref('');
const component = {
  PageContainer,
  EmailForm,
  EmailOTPForm,
  MFAChoice,
  AuthenticatorSetupForm,
  AuthenticatorOTPForm,
  SMSSetupForm,
  SMSOTPForm
}
const loginData = reactive({
  email: "",
});
const notification = reactive({
  authenticate: {
    asyncError: false
  }
})
const {hideLogoutNotification, hasLogoutNotification} = inject('logoutNotification')
function resetNotifications() {
  notification.authenticate.asyncError = false;
  hideLogoutNotification();
}
const envStore = useEnvStore();

const hasAuthenticatorMFA = computed(() => {
  return get(loginData, "mfaModes.authenticator", false);
});
const hasSMSMFA = computed(() => {
  return get(loginData, "mfaModes.sms", false);
});
const hasNoMFA = computed(() => {
  return !hasAuthenticatorMFA.value && !hasSMSMFA.value;
});
const hasAtLeastOneMFA = computed(() => {
  return !hasNoMFA.value;
});
const isSetupComponent = computed(() => {
  return (
    currentComponent.value === "AuthenticatorSetupForm"
    || currentComponent.value === "SMSSetupForm"
  );
});
const isEnterComponent = computed(() => {
  return (
    currentComponent.value === "AuthenticatorOTPForm"
    || currentComponent.value === "SMSOTPForm"
  );
})
const isMFAChoiceComponent = computed(() => {
  return currentComponent.value === "MFAChoice";
})

function resetLoginData() {
  resetNotifications();
  loginData.email = "";
}
function goToMFA() {
  if (hasAuthenticatorMFA.value && !hasSMSMFA.value) {
    setCurrentComponent("EnterAuthenticatorCode");
  } else if (hasSMSMFA.value && !hasAuthenticatorMFA.value) {
    setCurrentComponent("EnterSMSCode");
  } else {
    setCurrentComponent("MFAChoice");
  }
}
function setCurrentComponent(component) {
  resetNotifications();
  currentComponent.value = component;
}
function skipSetup() {
  delete loginData.totp;
  delete loginData.firstTime;
  delete loginData.mode;
  triggerAuthenticate();
}
async function triggerAuthenticate() {
  envStore.setGlobalLoading();
  resetNotifications();
  let err, tokens;
  [err, tokens] = await to(
    axios({
      url: `${ envStore.apiUrls.dutypay }/api/v0/authenticate`,
      data: loginData,
      method: "POST",
    })
  );
  if (err) {
    envStore.releaseGlobalLoading();
    notification.authenticate.asyncError = true;
    return;
  }
  tokens = tokens.data;
  localStorage.setItem("accessToken", tokens.accessToken);
  localStorage.setItem("refreshToken", tokens.refreshToken);
  let i18n = locale.value;
  envStore.releaseGlobalLoading();
  if (route.query.redirect) {
    window.location.href = `${ route.query.redirect }/#i18n=${ i18n }&token=${ tokens.oneTimeToken }`;
    return;
  }
  window.location.href = `${ envStore.appUrls.taxhub }/#i18n=${ i18n }&token=${ tokens.oneTimeToken }`;
}

onMounted(async() => {
  await router.isReady();
  let queryParams = route.query;
  //Check for emailAuthCode and email in URL Query Params
  if (queryParams.emailAuthCode && queryParams.email) {
    setCurrentComponent("EmailOTPForm");
    return;
  }
  setCurrentComponent("EmailForm");
})
</script>
<script>
export default {
  name: "LoginStages"
};
</script>

<template>
  <PageContainer>

    <DpNotification
      type="success"
      :visibility="hasLogoutNotification"
      :title="t('notification.logout.success.title')"
      :message="t('notification.logout.success.message')"/>

    <DpNotification
      type="error"
      :visibility="notification.authenticate.asyncError"
      :title="t('notification.authenticate.asyncError.title')"
      :message="t('notification.authenticate.asyncError.message')"/>

    <component
      v-bind:is="component[currentComponent]"
      v-model="loginData"
      v-on:reset-login-data="resetLoginData"
      v-on:go-to="setCurrentComponent($event)"
      v-on:trigger-authenticate="triggerAuthenticate"
      v-on:fetched-mfa-modes="goToMFA"
      v-on:login-form-set="loginData = $event"
    ></component>
    <template #footer>
      <div class="dp-flex wrap justify-content__space-around">
        <a
          v-if="isSetupComponent || isEnterComponent"
          @click="setCurrentComponent('MFAChoice')"
          @keypress="setCurrentComponent('MFAChoice')"
          tabindex="0"
        >{{ t("switchMethod.linkLabel") }}</a>
        <a
          v-if="(isSetupComponent || isMFAChoiceComponent) && hasNoMFA"
          @click="skipSetup"
          @keypress="skipSetup"
          tabindex="0"
        >{{ t("skipSetupThisTime.linkLabel") }}</a>
        <a
          v-if="isMFAChoiceComponent && hasAtLeastOneMFA"
          @click="setCurrentComponent('EmailForm')"
          @keypress="setCurrentComponent('EmailForm')"
          tabindex="0"
        >{{ t("goBackToLogin.linkLabel") }}</a>
      </div>
    </template>
  </PageContainer>
</template>

<i18n>
{
  "ar-AE": {
    "skipSetupThisTime": {
      "linkLabel": "يتخطى"
    },
    "switchMethod": {
      "linkLabel": "الرجوع إلى النظرة العامة"
    },
    "goBackToLogin": {
      "linkLabel": "عودة إلى صفحة تسجيل الدخول"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "خطأ",
          "message": "لا يمكن تأكيد رمز المصادقة."
        }
      },
      "logout": {
        "success": {
          "title": "عمل ناجح",
          "message": "نجح تسجيل الخروج"
        }
      }
    }
  },
  "ar-EG": {
    "skipSetupThisTime": {
      "linkLabel": "يتخطى"
    },
    "switchMethod": {
      "linkLabel": "الرجوع إلى النظرة العامة"
    },
    "goBackToLogin": {
      "linkLabel": "عودة إلى صفحة تسجيل الدخول"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "خطأ",
          "message": "لا يمكن تأكيد رمز المصادقة."
        }
      },
      "logout": {
        "success": {
          "title": "عمل ناجح",
          "message": "نجح تسجيل الخروج"
        }
      }
    }
  },
  "ar-SA": {
    "skipSetupThisTime": {
      "linkLabel": "يتخطى"
    },
    "switchMethod": {
      "linkLabel": "الرجوع إلى النظرة العامة"
    },
    "goBackToLogin": {
      "linkLabel": "عودة إلى صفحة تسجيل الدخول"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "خطأ",
          "message": "لا يمكن تأكيد رمز المصادقة."
        }
      },
      "logout": {
        "success": {
          "title": "عمل ناجح",
          "message": "نجح تسجيل الخروج"
        }
      }
    }
  },
  "el-GR": {
    "skipSetupThisTime": {
      "linkLabel": "Παράλειψη"
    },
    "switchMethod": {
      "linkLabel": "Επιστροφή στην επισκόπηση"
    },
    "goBackToLogin": {
      "linkLabel": "Επιστροφή στη σελίδα σύνδεσης"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Σφάλμα",
          "message": "Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας."
        }
      },
      "logout": {
        "success": {
          "title": "Επιτυχημένη ενέργεια",
          "message": "Η αποσύνδεση ήταν επιτυχής"
        }
      }
    }
  },
  "el-CY": {
    "skipSetupThisTime": {
      "linkLabel": "Παράλειψη"
    },
    "switchMethod": {
      "linkLabel": "Επιστροφή στην επισκόπηση"
    },
    "goBackToLogin": {
      "linkLabel": "Επιστροφή στη σελίδα σύνδεσης"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Σφάλμα",
          "message": "Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας."
        }
      },
      "logout": {
        "success": {
          "title": "Επιτυχημένη ενέργεια",
          "message": "Η αποσύνδεση ήταν επιτυχής"
        }
      }
    }
  },
  "de-DE": {
    "skipSetupThisTime": {
      "linkLabel": "Überspringen"
    },
    "switchMethod": {
      "linkLabel": "Zurück zur Übersicht"
    },
    "goBackToLogin": {
      "linkLabel": "Zurück zur Anmeldeseite"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Fehler",
          "message": "Der Authentifizierungscode konnte nicht bestätigt werden."
        }
      },
      "logout": {
        "success": {
          "title": "Erfolgreiche Aktion",
          "message": "Die Abmeldung war erfolgreich"
        }
      }
    }
  },
  "de-AT": {
    "skipSetupThisTime": {
      "linkLabel": "Überspringen"
    },
    "switchMethod": {
      "linkLabel": "Zurück zur Übersicht"
    },
    "goBackToLogin": {
      "linkLabel": "Zurück zur Anmeldeseite"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Fehler",
          "message": "Der Authentifizierungscode konnte nicht bestätigt werden."
        }
      },
      "logout": {
        "success": {
          "title": "Erfolgreiche Aktion",
          "message": "Die Abmeldung war erfolgreich"
        }
      }
    }
  },
  "de-CH": {
    "skipSetupThisTime": {
      "linkLabel": "Überspringen"
    },
    "switchMethod": {
      "linkLabel": "Zurück zur Übersicht"
    },
    "goBackToLogin": {
      "linkLabel": "Zurück zur Anmeldeseite"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Fehler",
          "message": "Der Authentifizierungscode konnte nicht bestätigt werden."
        }
      },
      "logout": {
        "success": {
          "title": "Erfolgreiche Aktion",
          "message": "Die Abmeldung war erfolgreich"
        }
      }
    }
  },
  "en-US": {
    "skipSetupThisTime": {
      "linkLabel": "Skip"
    },
    "switchMethod": {
      "linkLabel": "Back to overview"
    },
    "goBackToLogin": {
      "linkLabel": "Back to login page"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Error",
          "message": "The authentication code could not be confirmed."
        }
      },
      "logout": {
        "success": {
          "title": "Successful action",
          "message": "Logging out was successful"
        }
      }
    }
  },
  "en-GB": {
    "skipSetupThisTime": {
      "linkLabel": "Skip"
    },
    "switchMethod": {
      "linkLabel": "Back to overview"
    },
    "goBackToLogin": {
      "linkLabel": "Back to login page"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Error",
          "message": "The authentication code could not be confirmed."
        }
      },
      "logout": {
        "success": {
          "title": "Successful action",
          "message": "Logging out was successful"
        }
      }
    }
  },
  "it-IT": {
    "skipSetupThisTime": {
      "linkLabel": "Salta"
    },
    "switchMethod": {
      "linkLabel": "Torna alla panoramica"
    },
    "goBackToLogin": {
      "linkLabel": "Torna alla pagina di login"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Errore",
          "message": "Non è stato possibile confermare il codice di autenticazione."
        }
      },
      "logout": {
        "success": {
          "title": "Azione riuscita",
          "message": "La disconnessione ha avuto successo"
        }
      }
    }
  },
  "fr-FR": {
    "skipSetupThisTime": {
      "linkLabel": "Sauter"
    },
    "switchMethod": {
      "linkLabel": "Retour à la page d'accueil"
    },
    "goBackToLogin": {
      "linkLabel": "Retour à la page de connexion"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Erreur",
          "message": "Le code d'authentification n'a pas pu être confirmé."
        }
      },
      "logout": {
        "success": {
          "title": "Action réussie",
          "message": "La déconnexion a réussi"
        }
      }
    }
  },
  "es-ES": {
    "skipSetupThisTime": {
      "linkLabel": "Saltar"
    },
    "switchMethod": {
      "linkLabel": "Atrás"
    },
    "goBackToLogin": {
      "linkLabel": "Volver a la página de inicio de sesión"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Error",
          "message": "No se pudo confirmar el código de autenticación."
        }
      },
      "logout": {
        "success": {
          "title": "Listo",
          "message": "Se ha cerrado la sesión correctamente"
        }
      }
    }
  },
  "pl-PL": {
    "skipSetupThisTime": {
      "linkLabel": "Pomiń"
    },
    "switchMethod": {
      "linkLabel": "Powrót do przeglądu"
    },
    "goBackToLogin": {
      "linkLabel": "Powrót do strony logowania"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Błąd",
          "message": "Nie udało się potwierdzić kodu uwierzytelniającego."
        }
      },
      "logout": {
        "success": {
          "title": "Czynność zakończona powodzeniem",
          "message": "Wylogowanie powiodło się"
        }
      }
    }
  },
  "nl-NL": {
    "skipSetupThisTime": {
      "linkLabel": "Overslaan"
    },
    "switchMethod": {
      "linkLabel": "Terug naar overzicht"
    },
    "goBackToLogin": {
      "linkLabel": "Terug naar inlogpagina"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Fout",
          "message": "De authencitatiecode kon niet worden bevestigd."
        }
      },
      "logout": {
        "success": {
          "title": "Geslaagde actie",
          "message": "Uitloggen is gelukt"
        }
      }
    }
  },
  "bg-BG": {
    "skipSetupThisTime": {
      "linkLabel": "Пропускане"
    },
    "switchMethod": {
      "linkLabel": "Назад към общ преглед"
    },
    "goBackToLogin": {
      "linkLabel": "Обратно към страницата за вход"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "грешка",
          "message": "Кодът за удостоверяване не може да бъде потвърден."
        }
      },
      "logout": {
        "success": {
          "title": "Успешно действие",
          "message": "Излизането беше успешно"
        }
      }
    }
  },
  "bs-BA": {
    "skipSetupThisTime": {
      "linkLabel": "Preskoči"
    },
    "switchMethod": {
      "linkLabel": "Povratak na pregled"
    },
    "goBackToLogin": {
      "linkLabel": "Nazad na stranicu za prijavu"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Greška",
          "message": "Nije moguće potvrditi autentifikacijski kôd."
        }
      },
      "logout": {
        "success": {
          "title": "Uspješno obavljeno",
          "message": "Odjava je bila uspješna"
        }
      }
    }
  },
  "da-DK": {
    "skipSetupThisTime": {
      "linkLabel": "spring over"
    },
    "switchMethod": {
      "linkLabel": "Tilbage til oversigt"
    },
    "goBackToLogin": {
      "linkLabel": "Tilbage til loginsiden"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Fejl",
          "message": "Godkendelseskoden kunne ikke bekræftes."
        }
      },
      "logout": {
        "success": {
          "title": "Vellykket handling",
          "message": "Det lykkedes at logge ud"
        }
      }
    }
  },
  "et-EE": {
    "skipSetupThisTime": {
      "linkLabel": "Jäta vahele"
    },
    "switchMethod": {
      "linkLabel": "Tagasi ülevaate juurde"
    },
    "goBackToLogin": {
      "linkLabel": "Tagasi sisselogimise lehele"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Viga",
          "message": "Autentimiskoodi ei õnnestunud kinnitada."
        }
      },
      "logout": {
        "success": {
          "title": "Edukas tegevus",
          "message": "Väljalogimine õnnestus"
        }
      }
    }
  },
  "fi-FI": {
    "skipSetupThisTime": {
      "linkLabel": "Ohita"
    },
    "switchMethod": {
      "linkLabel": "Takaisin yleiskatsaukseen"
    },
    "goBackToLogin": {
      "linkLabel": "Takaisin kirjautumissivulle"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Virhe",
          "message": "Todennuskoodia ei voitu vahvistaa."
        }
      },
      "logout": {
        "success": {
          "title": "Onnistunut toiminta",
          "message": "Uloskirjautuminen onnistui"
        }
      }
    }
  },
  "he-IL": {
    "skipSetupThisTime": {
      "linkLabel": "לדלג"
    },
    "switchMethod": {
      "linkLabel": "חזרה לסקירה כללית"
    },
    "goBackToLogin": {
      "linkLabel": "חזרה לדף הכניסה"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "שְׁגִיאָה",
          "message": "לא ניתן היה לאשר את קוד האימות."
        }
      },
      "logout": {
        "success": {
          "title": "פעולה מוצלחת",
          "message": "ההתנתקות הצליחה"
        }
      }
    }
  },
  "ga-IE": {
    "skipSetupThisTime": {
      "linkLabel": "Scipeáil"
    },
    "switchMethod": {
      "linkLabel": "Ar ais go forbhreathnú"
    },
    "goBackToLogin": {
      "linkLabel": "Ar ais go dtí an leathanach logáil isteach"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Earráid",
          "message": "Níorbh fhéidir an cód fíordheimhnithe a dhearbhú."
        }
      },
      "logout": {
        "success": {
          "title": "Gníomh rathúil",
          "message": "D'éirigh leis an logáil amach"
        }
      }
    }
  },
  "ja-JP": {
    "skipSetupThisTime": {
      "linkLabel": "スキップ"
    },
    "switchMethod": {
      "linkLabel": "概要に戻る"
    },
    "goBackToLogin": {
      "linkLabel": "ログインページに戻ります"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "エラー",
          "message": "認証コードが確認できませんでした。"
        }
      },
      "logout": {
        "success": {
          "title": "成功したアクション",
          "message": "ログアウト成功"
        }
      }
    }
  },
  "hr-HR": {
    "skipSetupThisTime": {
      "linkLabel": "Preskočiti"
    },
    "switchMethod": {
      "linkLabel": "Povratak na pregled"
    },
    "goBackToLogin": {
      "linkLabel": "Vratite se na stranicu za prijavu"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Pogreška",
          "message": "Nije moguće potvrditi autentifikacijski kod."
        }
      },
      "logout": {
        "success": {
          "title": "Uspješna akcija",
          "message": "Odjava je bila uspješna"
        }
      }
    }
  },
  "lv-LV": {
    "skipSetupThisTime": {
      "linkLabel": "Izlaist"
    },
    "switchMethod": {
      "linkLabel": "Atgriezties uz kopsavilkumu"
    },
    "goBackToLogin": {
      "linkLabel": "Atgriezties uz pieteikšanās lapu"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Kļūda",
          "message": "Nevarēja apstiprināt autentifikācijas kodu."
        }
      },
      "logout": {
        "success": {
          "title": "Veiksmīga darbība",
          "message": "Atteikšanās sekmīga"
        }
      }
    }
  },
  "lt-LT": {
    "skipSetupThisTime": {
      "linkLabel": "Praleisti"
    },
    "switchMethod": {
      "linkLabel": "Grįžti į apžvalgą"
    },
    "goBackToLogin": {
      "linkLabel": "Grįžti į prisijungimo puslapį"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Klaida",
          "message": "Nepavyko patvirtinti autentifikavimo kodo."
        }
      },
      "logout": {
        "success": {
          "title": "Sėkmingas veiksmas",
          "message": "Atsijungimas buvo sėkmingas"
        }
      }
    }
  },
  "pt-PT": {
    "skipSetupThisTime": {
      "linkLabel": "Ignorar"
    },
    "switchMethod": {
      "linkLabel": "Voltar ao menu principal"
    },
    "goBackToLogin": {
      "linkLabel": "Voltar à página de início de sessão"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Erro",
          "message": "Não foi possível confirmar o código de autenticação."
        }
      },
      "logout": {
        "success": {
          "title": "Ação bem-sucedida",
          "message": "Logout bem-sucedido"
        }
      }
    }
  },
  "ro-RO": {
    "skipSetupThisTime": {
      "linkLabel": "Ignorare"
    },
    "switchMethod": {
      "linkLabel": "Înapoi la prezentarea generală"
    },
    "goBackToLogin": {
      "linkLabel": "Înapoi la pagina de conectare"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Eroare",
          "message": "Codul de autentificare nu a putut fi confirmat."
        }
      },
      "logout": {
        "success": {
          "title": "Acțiune reușită",
          "message": "Deconectarea a avut succes"
        }
      }
    }
  },
  "ru-RU": {
    "skipSetupThisTime": {
      "linkLabel": "Пропустить"
    },
    "switchMethod": {
      "linkLabel": "Вернуться к общим сведениям"
    },
    "goBackToLogin": {
      "linkLabel": "Назад на страницу входа"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Ошибка",
          "message": "Код аутентификации не подтвержден."
        }
      },
      "logout": {
        "success": {
          "title": "Успешное действие",
          "message": "Выход из системы прошел успешно"
        }
      }
    }
  },
  "sv-SE": {
    "skipSetupThisTime": {
      "linkLabel": "Hoppa över"
    },
    "switchMethod": {
      "linkLabel": "Tillbaka till översikt"
    },
    "goBackToLogin": {
      "linkLabel": "Återvänd till inloggningssidan"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Fel",
          "message": "Autentiseringskoden kunde inte bekräftas."
        }
      },
      "logout": {
        "success": {
          "title": "Åtgärd utförd",
          "message": "Utloggningen lyckades"
        }
      }
    }
  },
  "sk-SK": {
    "skipSetupThisTime": {
      "linkLabel": "Preskočiť"
    },
    "switchMethod": {
      "linkLabel": "Späť na prehľad"
    },
    "goBackToLogin": {
      "linkLabel": "Späť na prihlasovaciu stránku"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Chyba",
          "message": "Overovací kód nebolo možné potvrdiť."
        }
      },
      "logout": {
        "success": {
          "title": "Úspešná akcia",
          "message": "Odhlásenie bolo úspešné"
        }
      }
    }
  },
  "cs-CZ": {
    "skipSetupThisTime": {
      "linkLabel": "Přeskočit"
    },
    "switchMethod": {
      "linkLabel": "Zpět na přehled"
    },
    "goBackToLogin": {
      "linkLabel": "Zpět na přihlašovací stránku"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Chyba",
          "message": "Nelze potvrdit ověřovací kód."
        }
      },
      "logout": {
        "success": {
          "title": "Úspěšná akce",
          "message": "Odhlášení bylo úspěšné"
        }
      }
    }
  },
  "hu-HU": {
    "skipSetupThisTime": {
      "linkLabel": "Kihagy"
    },
    "switchMethod": {
      "linkLabel": "Vissza az áttekintéshez"
    },
    "goBackToLogin": {
      "linkLabel": "Vissza a bejelentkezési oldalra"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Hiba",
          "message": "A hitelesítő kódot nem sikerült megerősíteni."
        }
      },
      "logout": {
        "success": {
          "title": "Sikeres művelet",
          "message": "A kijelentkezés sikeres volt"
        }
      }
    }
  },
  "fr-BE": {
    "skipSetupThisTime": {
      "linkLabel": "Sauter"
    },
    "switchMethod": {
      "linkLabel": "Retour à la page d'accueil"
    },
    "goBackToLogin": {
      "linkLabel": "Retour à la page de connexion"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Erreur",
          "message": "Le code d'authentification n'a pas pu être confirmé."
        }
      },
      "logout": {
        "success": {
          "title": "Action réussie",
          "message": "La déconnexion a réussi"
        }
      }
    }
  },
  "fr-LU": {
    "skipSetupThisTime": {
      "linkLabel": "Sauter"
    },
    "switchMethod": {
      "linkLabel": "Retour à la page d'accueil"
    },
    "goBackToLogin": {
      "linkLabel": "Retour à la page de connexion"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Erreur",
          "message": "Le code d'authentification n'a pas pu être confirmé."
        }
      },
      "logout": {
        "success": {
          "title": "Action réussie",
          "message": "La déconnexion a réussi"
        }
      }
    }
  },
  "mt-MT": {
    "skipSetupThisTime": {
      "linkLabel": "Aqbeż"
    },
    "switchMethod": {
      "linkLabel": "Lura għall-ħarsa ġenerali"
    },
    "goBackToLogin": {
      "linkLabel": "Lura lejn il-paġna tad-dħul"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Żball",
          "message": "Il-kodiċi ta' awtentikazzjoni ma setax jiġi kkonfermat."
        }
      },
      "logout": {
        "success": {
          "title": "Azzjoni ta' suċċess",
          "message": "Id-dereġistrazzjoni kienet suċċess"
        }
      }
    }
  },
  "sl-SI": {
    "skipSetupThisTime": {
      "linkLabel": "Preskoči"
    },
    "switchMethod": {
      "linkLabel": "Nazaj na pregled"
    },
    "goBackToLogin": {
      "linkLabel": "Nazaj na prijavo"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Napaka",
          "message": "Kode za preverjanje pristnosti ni bilo mogoče potrditi."
        }
      },
      "logout": {
        "success": {
          "title": "Uspešno opravljeno",
          "message": "Odjava je uspela"
        }
      }
    }
  },
  "tr-TR": {
    "skipSetupThisTime": {
      "linkLabel": "Atla"
    },
    "switchMethod": {
      "linkLabel": "Genel bakışa dön"
    },
    "goBackToLogin": {
      "linkLabel": "Oturum açma sayfasına dön"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Hata",
          "message": "Kimlik doğrulama kodu onaylanamadı."
        }
      },
      "logout": {
        "success": {
          "title": "Başarılı eylem",
          "message": "Çıkış başarılı oldu"
        }
      }
    }
  },
  "tr-CY": {
    "skipSetupThisTime": {
      "linkLabel": "Atla"
    },
    "switchMethod": {
      "linkLabel": "Genel bakışa dön"
    },
    "goBackToLogin": {
      "linkLabel": "Oturum açma sayfasına dön"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Hata",
          "message": "Kimlik doğrulama kodu onaylanamadı."
        }
      },
      "logout": {
        "success": {
          "title": "Başarılı eylem",
          "message": "Çıkış başarılı oldu"
        }
      }
    }
  },
  "uk-UA": {
    "skipSetupThisTime": {
      "linkLabel": "Überspringen"
    },
    "switchMethod": {
      "linkLabel": "Zurück zur Übersicht"
    },
    "goBackToLogin": {
      "linkLabel": "Повернутися до сторінки входу"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "Помилка",
          "message": "Не вдалося підтвердити код автентифікації."
        }
      },
      "logout": {
        "success": {
          "title": "Успішна акція",
          "message": "Вихід успішно виконано"
        }
      }
    }
  },
  "zh-CN": {
    "skipSetupThisTime": {
      "linkLabel": "跳过"
    },
    "switchMethod": {
      "linkLabel": "返回概览"
    },
    "goBackToLogin": {
      "linkLabel": "返回登录页面"
    },
    "notification": {
      "authenticate": {
        "asyncError": {
          "title": "错误",
          "message": "无法确认验证码。"
        }
      },
      "logout": {
        "success": {
          "title": "操作成功",
          "message": "注销成功"
        }
      }
    }
  }
}
</i18n>

<style lang="scss"></style>
