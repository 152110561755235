<script setup>
import { defineEmits, defineProps, reactive, ref, computed, inject, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { to } from "await-to-js";
import { useEnvStore } from "@dutypay/store-modules";
import { interceptor } from "@dutypay/utilities";
import {useRoute, useRouter} from 'vue-router';

const envStore = useEnvStore();
const { t } = useI18n();
const composables = inject("composables");
const emit = defineEmits(["update", "reset-login-data", "go-to"]);
const props = defineProps({
  modelValue: { type: Object, required: true },
});
const loginData = composables.useTwoWayBinding(props, emit, "modelValue");

const notification = reactive({
  sendEmail: {
    success: false
  },
  verifyEmailOTP: {
    asyncError: false
  }
});
const isLoadingAuthEmail = ref(false);
const isResendDisabled = ref(true);
const countdown = ref(20);
const emailDisguised = computed(() => {
  // Show only first three characters of email address name
  let email = loginData.value.email.split("@");
  email[0] = email[0].substr(0, 3) + "...";
  return email.join("@");
});

// Verify Email OTP from input
async function verifyEmailOTP() {
  notification.verifyEmailOTP.asyncError = false;
  let err, mfaModes;
  [err, mfaModes] = await to(interceptor.post(`${ envStore.apiUrls.dutypay }/api/v0/check-mfa`, { ...loginData.value }));
  if (err) {
    notification.verifyEmailOTP.asyncError = true;
    return;
  }
  loginData.value.mfaModes = mfaModes.data.mfaModes;
  emit("go-to", "MFAChoice");
  return;
}

function countDownTimer() {
  if (countdown.value > 0) {
    setTimeout(() => {
      countdown.value -= 1;
      countDownTimer();
    }, 1000);
  } else {
    isResendDisabled.value = false;
  }
}
// Send Email with OTP and Authentication Link
async function sendEmailOTP() {
  isLoadingAuthEmail.value = true;
  isResendDisabled.value = true;
  notification.sendEmail.success = false;
  notification.verifyEmailOTP.asyncError = false;
  let err;
  [err] = await to(interceptor.post(`${ envStore.apiUrls.dutypay }/api/v0/send/auth-email`, { ...loginData.value }));
  isLoadingAuthEmail.value = false;
  if (err) {
    isResendDisabled.value = false;
  }
  countdown.value = 20;
  countDownTimer();
  notification.sendEmail.success = true
}

onMounted(async () => {
  const router = useRouter();
  const route = useRoute();
  await router.isReady();
  // Extract Query Params from route
  let queryParams = route.query;
  // Check for emailAuthCode and email in URL Query Params
  if (queryParams.emailAuthCode && queryParams.email) {
    loginData.value.email = queryParams.email;
    //Check if user exists
    isLoadingAuthEmail.value = true;
    isResendDisabled.value = true;
    notification.sendEmail.success = false;
    notification.verifyEmailOTP.asyncError = false;
    let err;
    [err] = await to(interceptor.post(`${ envStore.apiUrls.dutypay }/api/v0/send/auth-email`, { ...loginData.value }));
    isLoadingAuthEmail.value = false;
    if (err) {
      isResendDisabled.value = false;
    }
    countdown.value = 20;
    countDownTimer();
    notification.sendEmail.success = true
    loginData.value.emailOTP = queryParams.emailAuthCode;
    countDownTimer();
    return;
  }
  await sendEmailOTP();
});
</script>
<script>
export default {
  name: "EmailOTPForm"
}
</script>

<template>
  <div>
    <h2>{{ t("emailOTPForm.title") }}</h2>

    <DpNotification
      type="success"
      :visibility="notification.sendEmail.success"
      :title="t('notification.sendEmail.success.title')"
      :message="t('notification.sendEmail.success.message')">
      <template #controls>
        <dp-button
          purpose="secondaryAction"
          class="full-width"
          :disabled="isResendDisabled"
          style="margin: 1em 0;"
          @click="sendEmailOTP">
            <span v-if="isResendDisabled">{{
                t("resendEmailInSeconds.buttonLabel", { seconds: countdown })
              }}</span>
          <span v-else>{{ t("resendEmail.buttonLabel") }}</span>
        </dp-button>
      </template>
    </DpNotification>

    <DpNotification
      type="error"
      :visibility="notification.verifyEmailOTP.asyncError"
      :title="t('notification.verifyEmailOTP.asyncError.title')"
      :message="t('notification.verifyEmailOTP.asyncError.message')"
    ></DpNotification>

    <DpForm
      v-model="loginData"
      v-loading="isLoadingAuthEmail"
      hide-required-asterisk
      reference="email-otp-form"
      :action="verifyEmailOTP"
      :primaryButtonLabel="t('confirmCode.buttonLabel')"
      :primaryButtonParameter="{
        class: ['full-width'],
        size: 'large',
      }"
      disable-notification>
      <p>{{ t("unlockWith.label", { email: emailDisguised }) }}</p>
      <p v-show="!loginData.emailIsVerified">{{ t("emailNotVerified.label") }}</p>
      <DpInputMultiFactorAuthenticationCode
        v-model="loginData.emailOTP"
        prop="emailOTP"
        required
        :input-parameters="{ size: 'large' }"/>
    </DpForm>
  </div>
</template>

<i18n>
{
  "ar-AE": {
    "unlockWith": {
      "label": "أدخل وتأكد من استلام رمز المصادقة."
    },
    "notification": {
      "emailSent": {
        "title": "عمل ناجح",
        "message": "تم إرسال رمز المصادقة عبر البريد الإلكتروني. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة للتسجيل."
      },
      "emailOTPNotVerified": {
        "title": "خطأ",
        "message": "لا يمكن تأكيد رمز المصادقة."
      },
      "verifyEmailOTP": {
        "message": "لا يمكن تأكيد رمز المصادقة.",
        "title": "خطأ",
        "asyncError": {
          "message": "لا يمكن تأكيد رمز المصادقة.",
          "title": "خطأ"
        }
      },
      "sendEmail": {
        "message": "تم إرسال رمز المصادقة عبر البريد الإلكتروني. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة للتسجيل.",
        "title": "عمل ناجح",
        "success": {
          "message": "تم إرسال رمز المصادقة عبر البريد الإلكتروني. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة للتسجيل.",
          "title": "عمل ناجح"
        }
      }
    },
    "emailOTPForm": {
      "title": "رمز المصادقة عن طريق البريد الإلكتروني"
    },
    "confirmCode": {
      "buttonLabel": "تأكيد"
    },
    "resendEmail": {
      "buttonLabel": "أعد الإرسال"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "إعادة إرسال في {seconds} ثوان"
    }
  },
  "ar-EG": {
    "unlockWith": {
      "label": "أدخل وتأكد من استلام رمز المصادقة."
    },
    "notification": {
      "emailSent": {
        "title": "عمل ناجح",
        "message": "تم إرسال رمز المصادقة عبر البريد الإلكتروني. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة للتسجيل."
      },
      "emailOTPNotVerified": {
        "title": "خطأ",
        "message": "لا يمكن تأكيد رمز المصادقة."
      },
      "verifyEmailOTP": {
        "message": "لا يمكن تأكيد رمز المصادقة.",
        "title": "خطأ",
        "asyncError": {
          "message": "لا يمكن تأكيد رمز المصادقة.",
          "title": "خطأ"
        }
      },
      "sendEmail": {
        "message": "تم إرسال رمز المصادقة عبر البريد الإلكتروني. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة للتسجيل.",
        "title": "عمل ناجح",
        "success": {
          "message": "تم إرسال رمز المصادقة عبر البريد الإلكتروني. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة للتسجيل.",
          "title": "عمل ناجح"
        }
      }
    },
    "emailOTPForm": {
      "title": "رمز المصادقة عن طريق البريد الإلكتروني"
    },
    "confirmCode": {
      "buttonLabel": "تأكيد"
    },
    "resendEmail": {
      "buttonLabel": "أعد الإرسال"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "إعادة إرسال في {seconds} ثوان"
    }
  },
  "ar-SA": {
    "unlockWith": {
      "label": "أدخل وتأكد من استلام رمز المصادقة."
    },
    "notification": {
      "emailSent": {
        "title": "عمل ناجح",
        "message": "تم إرسال رمز المصادقة عبر البريد الإلكتروني. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة للتسجيل."
      },
      "emailOTPNotVerified": {
        "title": "خطأ",
        "message": "لا يمكن تأكيد رمز المصادقة."
      },
      "verifyEmailOTP": {
        "message": "لا يمكن تأكيد رمز المصادقة.",
        "title": "خطأ",
        "asyncError": {
          "message": "لا يمكن تأكيد رمز المصادقة.",
          "title": "خطأ"
        }
      },
      "sendEmail": {
        "message": "تم إرسال رمز المصادقة عبر البريد الإلكتروني. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة للتسجيل.",
        "title": "عمل ناجح",
        "success": {
          "message": "تم إرسال رمز المصادقة عبر البريد الإلكتروني. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة للتسجيل.",
          "title": "عمل ناجح"
        }
      }
    },
    "emailOTPForm": {
      "title": "رمز المصادقة عن طريق البريد الإلكتروني"
    },
    "confirmCode": {
      "buttonLabel": "تأكيد"
    },
    "resendEmail": {
      "buttonLabel": "أعد الإرسال"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "إعادة إرسال في {seconds} ثوان"
    }
  },
  "el-GR": {
    "unlockWith": {
      "label": "Εισαγάγετε και επιβεβαιώστε τον κωδικό ελέγχου ταυτότητας που λάβατε."
    },
    "notification": {
      "emailSent": {
        "title": "Επιτυχημένη ενέργεια",
        "message": "Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω email. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για εγγραφή."
      },
      "emailOTPNotVerified": {
        "title": "Σφάλμα",
        "message": "Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας."
      },
      "verifyEmailOTP": {
        "message": "Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας.",
        "title": "Σφάλμα",
        "asyncError": {
          "message": "Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας.",
          "title": "Σφάλμα"
        }
      },
      "sendEmail": {
        "message": "Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω email. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για εγγραφή.",
        "title": "Επιτυχημένη ενέργεια",
        "success": {
          "message": "Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω email. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για εγγραφή.",
          "title": "Επιτυχημένη ενέργεια"
        }
      }
    },
    "emailOTPForm": {
      "title": "Κωδικός ελέγχου ταυτότητας μέσω email"
    },
    "confirmCode": {
      "buttonLabel": "Επιβεβαίωση"
    },
    "resendEmail": {
      "buttonLabel": "Στείλε ξανά"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Επανάληψη αποστολής σε {seconds} δευτερόλεπτα"
    }
  },
  "el-CY": {
    "unlockWith": {
      "label": "Εισαγάγετε και επιβεβαιώστε τον κωδικό ελέγχου ταυτότητας που λάβατε."
    },
    "notification": {
      "emailSent": {
        "title": "Επιτυχημένη ενέργεια",
        "message": "Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω email. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για εγγραφή."
      },
      "emailOTPNotVerified": {
        "title": "Σφάλμα",
        "message": "Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας."
      },
      "verifyEmailOTP": {
        "message": "Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας.",
        "title": "Σφάλμα",
        "asyncError": {
          "message": "Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας.",
          "title": "Σφάλμα"
        }
      },
      "sendEmail": {
        "message": "Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω email. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για εγγραφή.",
        "title": "Επιτυχημένη ενέργεια",
        "success": {
          "message": "Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω email. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για εγγραφή.",
          "title": "Επιτυχημένη ενέργεια"
        }
      }
    },
    "emailOTPForm": {
      "title": "Κωδικός ελέγχου ταυτότητας μέσω email"
    },
    "confirmCode": {
      "buttonLabel": "Επιβεβαίωση"
    },
    "resendEmail": {
      "buttonLabel": "Στείλε ξανά"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Επανάληψη αποστολής σε {seconds} δευτερόλεπτα"
    }
  },
  "de-DE": {
    "unlockWith": {
      "label": "Empfangenen Authentifizierungscode eingeben und bestätigen."
    },
    "notification": {
      "emailSent": {
        "title": "Erfolgreiche Aktion",
        "message": "Der Authentifizierungscode wurde per E-Mail gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."
      },
      "emailOTPNotVerified": {
        "title": "Fehler",
        "message": "Der Authentifizierungscode konnte nicht bestätigt werden."
      },
      "verifyEmailOTP": {
        "message": "Der Authentifizierungscode konnte nicht bestätigt werden.",
        "title": "Fehler",
        "asyncError": {
          "message": "Der Authentifizierungscode konnte nicht bestätigt werden.",
          "title": "Fehler"
        }
      },
      "sendEmail": {
        "message": "Der Authentifizierungscode wurde per E-Mail gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden.",
        "title": "Erfolgreiche Aktion",
        "success": {
          "message": "Der Authentifizierungscode wurde per E-Mail gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden.",
          "title": "Erfolgreiche Aktion"
        }
      }
    },
    "emailOTPForm": {
      "title": "Authentifizierungscode per E-Mail"
    },
    "confirmCode": {
      "buttonLabel": "Bestätigen"
    },
    "resendEmail": {
      "buttonLabel": "Erneut senden"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Erneut senden in {seconds} Sekunden"
    }
  },
  "de-AT": {
    "unlockWith": {
      "label": "Empfangenen Authentifizierungscode eingeben und bestätigen."
    },
    "notification": {
      "emailSent": {
        "title": "Erfolgreiche Aktion",
        "message": "Der Authentifizierungscode wurde per E-Mail gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."
      },
      "emailOTPNotVerified": {
        "title": "Fehler",
        "message": "Der Authentifizierungscode konnte nicht bestätigt werden."
      },
      "verifyEmailOTP": {
        "message": "Der Authentifizierungscode konnte nicht bestätigt werden.",
        "title": "Fehler",
        "asyncError": {
          "message": "Der Authentifizierungscode konnte nicht bestätigt werden.",
          "title": "Fehler"
        }
      },
      "sendEmail": {
        "message": "Der Authentifizierungscode wurde per E-Mail gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden.",
        "title": "Erfolgreiche Aktion",
        "success": {
          "message": "Der Authentifizierungscode wurde per E-Mail gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden.",
          "title": "Erfolgreiche Aktion"
        }
      }
    },
    "emailOTPForm": {
      "title": "Authentifizierungscode per E-Mail"
    },
    "confirmCode": {
      "buttonLabel": "Bestätigen"
    },
    "resendEmail": {
      "buttonLabel": "Erneut senden"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Erneut senden in {seconds} Sekunden"
    }
  },
  "de-CH": {
    "unlockWith": {
      "label": "Empfangenen Authentifizierungscode eingeben und bestätigen."
    },
    "notification": {
      "emailSent": {
        "title": "Erfolgreiche Aktion",
        "message": "Der Authentifizierungscode wurde per E-Mail gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."
      },
      "emailOTPNotVerified": {
        "title": "Fehler",
        "message": "Der Authentifizierungscode konnte nicht bestätigt werden."
      },
      "verifyEmailOTP": {
        "message": "Der Authentifizierungscode konnte nicht bestätigt werden.",
        "title": "Fehler",
        "asyncError": {
          "message": "Der Authentifizierungscode konnte nicht bestätigt werden.",
          "title": "Fehler"
        }
      },
      "sendEmail": {
        "message": "Der Authentifizierungscode wurde per E-Mail gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden.",
        "title": "Erfolgreiche Aktion",
        "success": {
          "message": "Der Authentifizierungscode wurde per E-Mail gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden.",
          "title": "Erfolgreiche Aktion"
        }
      }
    },
    "emailOTPForm": {
      "title": "Authentifizierungscode per E-Mail"
    },
    "confirmCode": {
      "buttonLabel": "Bestätigen"
    },
    "resendEmail": {
      "buttonLabel": "Erneut senden"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Erneut senden in {seconds} Sekunden"
    }
  },
  "en-US": {
    "unlockWith": {
      "label": "Enter and confirm the authentication code received."
    },
    "notification": {
      "emailSent": {
        "title": "Successful action",
        "message": "The authentication code was sent via e-mail. It can only be used once and within a limited time for logging in."
      },
      "emailOTPNotVerified": {
        "title": "Error",
        "message": "The authentication code could not be confirmed."
      },
      "verifyEmailOTP": {
        "message": "The authentication code could not be confirmed.",
        "title": "Error",
        "asyncError": {
          "message": "The authentication code could not be confirmed.",
          "title": "Error"
        }
      },
      "sendEmail": {
        "message": "The authentication code was sent via e-mail. It can only be used once and within a limited time for logging in.",
        "title": "Successful action",
        "success": {
          "message": "The authentication code was sent via e-mail. It can only be used once and within a limited time for logging in.",
          "title": "Successful action"
        }
      }
    },
    "emailOTPForm": {
      "title": "Authentication code by E-mail"
    },
    "confirmCode": {
      "buttonLabel": "Confirm"
    },
    "resendEmail": {
      "buttonLabel": "Send again"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Resend in {seconds} seconds"
    }
  },
  "en-GB": {
    "unlockWith": {
      "label": "Enter and confirm the authentication code received."
    },
    "notification": {
      "emailSent": {
        "title": "Successful action",
        "message": "The authentication code was sent via email. It can only be used once and within a limited time for registration."
      },
      "emailOTPNotVerified": {
        "title": "Error",
        "message": "The authentication code could not be confirmed."
      },
      "verifyEmailOTP": {
        "message": "The authentication code could not be confirmed.",
        "title": "Error",
        "asyncError": {
          "message": "The authentication code could not be confirmed.",
          "title": "Error"
        }
      },
      "sendEmail": {
        "message": "The authentication code was sent via email. It can only be used once and within a limited time for registration.",
        "title": "Successful action",
        "success": {
          "message": "The authentication code was sent via email. It can only be used once and within a limited time for registration.",
          "title": "Successful action"
        }
      }
    },
    "emailOTPForm": {
      "title": "Authentication code by E-mail"
    },
    "confirmCode": {
      "buttonLabel": "Confirm"
    },
    "resendEmail": {
      "buttonLabel": "Send again"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Resend in {seconds} seconds"
    }
  },
  "it-IT": {
    "unlockWith": {
      "label": "Inserisci e conferma il codice di autenticazione ricevuto."
    },
    "notification": {
      "emailSent": {
        "title": "Azione riuscita",
        "message": "Il codice di autenticazione è stato inviato via e-mail. Può essere utilizzato solo una volta ed entro un tempo limitato per la registrazione."
      },
      "emailOTPNotVerified": {
        "title": "Errore",
        "message": "Non è stato possibile confermare il codice di autenticazione."
      },
      "verifyEmailOTP": {
        "message": "Non è stato possibile confermare il codice di autenticazione.",
        "title": "Errore",
        "asyncError": {
          "message": "Non è stato possibile confermare il codice di autenticazione.",
          "title": "Errore"
        }
      },
      "sendEmail": {
        "message": "Il codice di autenticazione è stato inviato via e-mail. Può essere utilizzato solo una volta ed entro un tempo limitato per la registrazione.",
        "title": "Azione riuscita",
        "success": {
          "message": "Il codice di autenticazione è stato inviato via e-mail. Può essere utilizzato solo una volta ed entro un tempo limitato per la registrazione.",
          "title": "Azione riuscita"
        }
      }
    },
    "emailOTPForm": {
      "title": "Codice di autenticazione via e-mail"
    },
    "confirmCode": {
      "buttonLabel": "Conferma"
    },
    "resendEmail": {
      "buttonLabel": "Invia di nuovo"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Invia nuovamente tra {seconds} secondi"
    }
  },
  "fr-FR": {
    "unlockWith": {
      "label": "Entrez et confirmez le code d'authentification reçu."
    },
    "notification": {
      "emailSent": {
        "title": "Action réussie",
        "message": "Le code d'authentification a été envoyé par e-mail. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour l'enregistrement."
      },
      "emailOTPNotVerified": {
        "title": "Erreur",
        "message": "Le code d'authentification n'a pas pu être confirmé."
      },
      "verifyEmailOTP": {
        "message": "Le code d'authentification n'a pas pu être confirmé.",
        "title": "Erreur",
        "asyncError": {
          "message": "Le code d'authentification n'a pas pu être confirmé.",
          "title": "Erreur"
        }
      },
      "sendEmail": {
        "message": "Le code d'authentification a été envoyé par e-mail. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour l'enregistrement.",
        "title": "Action réussie",
        "success": {
          "message": "Le code d'authentification a été envoyé par e-mail. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour l'enregistrement.",
          "title": "Action réussie"
        }
      }
    },
    "emailOTPForm": {
      "title": "Code d'authentification par email"
    },
    "confirmCode": {
      "buttonLabel": "Confirmer"
    },
    "resendEmail": {
      "buttonLabel": "Envoyer à nouveau"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Renvoyer dans {seconds} secondes"
    }
  },
  "es-ES": {
    "unlockWith": {
      "label": "Introduzca y confirme el código de autenticación recibido."
    },
    "notification": {
      "emailSent": {
        "title": "Listo",
        "message": "El código de autenticación se envió por correo electrónico. Solo se puede usar una vez y dentro de un tiempo limitado para el registro."
      },
      "emailOTPNotVerified": {
        "title": "Error",
        "message": "No se pudo confirmar el código de autenticación."
      },
      "verifyEmailOTP": {
        "message": "No se pudo confirmar el código de autenticación.",
        "title": "Error",
        "asyncError": {
          "message": "No se pudo confirmar el código de autenticación.",
          "title": "Error"
        }
      },
      "sendEmail": {
        "message": "El código de autenticación se envió por correo electrónico. Solo se puede usar una vez y dentro de un tiempo limitado para el registro.",
        "title": "Listo",
        "success": {
          "message": "El código de autenticación se envió por correo electrónico. Solo se puede usar una vez y dentro de un tiempo limitado para el registro.",
          "title": "Listo"
        }
      }
    },
    "emailOTPForm": {
      "title": "Código de autenticación por correo electrónico"
    },
    "confirmCode": {
      "buttonLabel": "Confirmar"
    },
    "resendEmail": {
      "buttonLabel": "Enviar de nuevo"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Enviar de nuevo en {seconds} segundos"
    }
  },
  "pl-PL": {
    "unlockWith": {
      "label": "Wprowadź i potwierdź otrzymany kod uwierzytelniający."
    },
    "notification": {
      "emailSent": {
        "title": "Czynność zakończona powodzeniem",
        "message": "Kod uwierzytelniający został wysłany e-mailem. Można go użyć tylko raz iw ograniczonym czasie do rejestracji."
      },
      "emailOTPNotVerified": {
        "title": "Błąd",
        "message": "Nie udało się potwierdzić kodu uwierzytelniającego."
      },
      "verifyEmailOTP": {
        "message": "Nie udało się potwierdzić kodu uwierzytelniającego.",
        "title": "Błąd",
        "asyncError": {
          "message": "Nie udało się potwierdzić kodu uwierzytelniającego.",
          "title": "Błąd"
        }
      },
      "sendEmail": {
        "message": "Kod uwierzytelniający został wysłany e-mailem. Można go użyć tylko raz iw ograniczonym czasie do rejestracji.",
        "title": "Czynność zakończona powodzeniem",
        "success": {
          "message": "Kod uwierzytelniający został wysłany e-mailem. Można go użyć tylko raz iw ograniczonym czasie do rejestracji.",
          "title": "Czynność zakończona powodzeniem"
        }
      }
    },
    "emailOTPForm": {
      "title": "Kod uwierzytelniający przez e-mail"
    },
    "confirmCode": {
      "buttonLabel": "Potwierdź"
    },
    "resendEmail": {
      "buttonLabel": "Wyślij ponownie"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Wyślij ponownie za {seconds} s"
    }
  },
  "nl-NL": {
    "unlockWith": {
      "label": "Voer de ontvangen authenticatiecode in en bevestig deze."
    },
    "notification": {
      "emailSent": {
        "title": "Geslaagde actie",
        "message": "De authenticatiecode is per e-mail verzonden. Het kan slechts één keer en binnen een beperkte tijd worden gebruikt voor registratie."
      },
      "emailOTPNotVerified": {
        "title": "Fout",
        "message": "De authencitatiecode kon niet worden bevestigd."
      },
      "verifyEmailOTP": {
        "message": "De authencitatiecode kon niet worden bevestigd.",
        "title": "Fout",
        "asyncError": {
          "message": "De authencitatiecode kon niet worden bevestigd.",
          "title": "Fout"
        }
      },
      "sendEmail": {
        "message": "De authenticatiecode is per e-mail verzonden. Het kan slechts één keer en binnen een beperkte tijd worden gebruikt voor registratie.",
        "title": "Geslaagde actie",
        "success": {
          "message": "De authenticatiecode is per e-mail verzonden. Het kan slechts één keer en binnen een beperkte tijd worden gebruikt voor registratie.",
          "title": "Geslaagde actie"
        }
      }
    },
    "emailOTPForm": {
      "title": "Authenticatiecode per e-mail"
    },
    "confirmCode": {
      "buttonLabel": "Bevestigen"
    },
    "resendEmail": {
      "buttonLabel": "Opnieuw verzenden"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Verzenden over {seconds} seconden"
    }
  },
  "bg-BG": {
    "unlockWith": {
      "label": "Въведете и потвърдете получения код за удостоверяване."
    },
    "notification": {
      "emailSent": {
        "title": "Успешно действие",
        "message": "Кодът за удостоверяване беше изпратен по имейл. Може да се използва само веднъж и в рамките на ограничено време за регистрация."
      },
      "emailOTPNotVerified": {
        "title": "грешка",
        "message": "Кодът за удостоверяване не може да бъде потвърден."
      },
      "verifyEmailOTP": {
        "message": "Кодът за удостоверяване не може да бъде потвърден.",
        "title": "грешка",
        "asyncError": {
          "message": "Кодът за удостоверяване не може да бъде потвърден.",
          "title": "грешка"
        }
      },
      "sendEmail": {
        "message": "Кодът за удостоверяване беше изпратен по имейл. Може да се използва само веднъж и в рамките на ограничено време за регистрация.",
        "title": "Успешно действие",
        "success": {
          "message": "Кодът за удостоверяване беше изпратен по имейл. Може да се използва само веднъж и в рамките на ограничено време за регистрация.",
          "title": "Успешно действие"
        }
      }
    },
    "emailOTPForm": {
      "title": "Код за удостоверяване по имейл"
    },
    "confirmCode": {
      "buttonLabel": "Потвърждение"
    },
    "resendEmail": {
      "buttonLabel": "Изпрати отново"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Изпращане след {seconds} секунди"
    }
  },
  "bs-BA": {
    "unlockWith": {
      "label": "Unesite i potvrdite primljeni kod za autentifikaciju."
    },
    "notification": {
      "emailSent": {
        "title": "Uspješno obavljeno",
        "message": "Kod za autentifikaciju je poslan putem e-pošte. Može se koristiti samo jednom iu ograničenom vremenu za registraciju."
      },
      "emailOTPNotVerified": {
        "title": "Greška",
        "message": "Nije moguće potvrditi autentifikacijski kôd."
      },
      "verifyEmailOTP": {
        "message": "Nije moguće potvrditi autentifikacijski kôd.",
        "title": "Greška",
        "asyncError": {
          "message": "Nije moguće potvrditi autentifikacijski kôd.",
          "title": "Greška"
        }
      },
      "sendEmail": {
        "message": "Kod za autentifikaciju je poslan putem e-pošte. Može se koristiti samo jednom iu ograničenom vremenu za registraciju.",
        "title": "Uspješno obavljeno",
        "success": {
          "message": "Kod za autentifikaciju je poslan putem e-pošte. Može se koristiti samo jednom iu ograničenom vremenu za registraciju.",
          "title": "Uspješno obavljeno"
        }
      }
    },
    "emailOTPForm": {
      "title": "Autentifikacijski kod e-poštom"
    },
    "confirmCode": {
      "buttonLabel": "Potvrdite"
    },
    "resendEmail": {
      "buttonLabel": "Pošalji ponovo"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Ponovo pošalji za {seconds} sekundi"
    }
  },
  "da-DK": {
    "unlockWith": {
      "label": "Indtast og bekræft den modtagne godkendelseskode."
    },
    "notification": {
      "emailSent": {
        "title": "Vellykket handling",
        "message": "Godkendelseskoden blev sendt via e-mail. Den kan kun bruges én gang og inden for en begrænset tid til registrering."
      },
      "emailOTPNotVerified": {
        "title": "Fejl",
        "message": "Godkendelseskoden kunne ikke bekræftes."
      },
      "verifyEmailOTP": {
        "message": "Godkendelseskoden kunne ikke bekræftes.",
        "title": "Fejl",
        "asyncError": {
          "message": "Godkendelseskoden kunne ikke bekræftes.",
          "title": "Fejl"
        }
      },
      "sendEmail": {
        "message": "Godkendelseskoden blev sendt via e-mail. Den kan kun bruges én gang og inden for en begrænset tid til registrering.",
        "title": "Vellykket handling",
        "success": {
          "message": "Godkendelseskoden blev sendt via e-mail. Den kan kun bruges én gang og inden for en begrænset tid til registrering.",
          "title": "Vellykket handling"
        }
      }
    },
    "emailOTPForm": {
      "title": "Godkendelseskode via e-mail"
    },
    "confirmCode": {
      "buttonLabel": "Bekræft"
    },
    "resendEmail": {
      "buttonLabel": "Send igen"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Send igen om {seconds} sekunder"
    }
  },
  "et-EE": {
    "unlockWith": {
      "label": "Sisestage ja kinnitage saadud autentimiskood."
    },
    "notification": {
      "emailSent": {
        "title": "Edukas tegevus",
        "message": "Autentimiskood saadeti meili teel. Seda saab registreerimiseks kasutada ainult üks kord ja piiratud aja jooksul."
      },
      "emailOTPNotVerified": {
        "title": "Viga",
        "message": "Autentimiskoodi ei õnnestunud kinnitada."
      },
      "verifyEmailOTP": {
        "message": "Autentimiskoodi ei õnnestunud kinnitada.",
        "title": "Viga",
        "asyncError": {
          "message": "Autentimiskoodi ei õnnestunud kinnitada.",
          "title": "Viga"
        }
      },
      "sendEmail": {
        "message": "Autentimiskood saadeti meili teel. Seda saab registreerimiseks kasutada ainult üks kord ja piiratud aja jooksul.",
        "title": "Edukas tegevus",
        "success": {
          "message": "Autentimiskood saadeti meili teel. Seda saab registreerimiseks kasutada ainult üks kord ja piiratud aja jooksul.",
          "title": "Edukas tegevus"
        }
      }
    },
    "emailOTPForm": {
      "title": "Autentimiskood e-posti teel"
    },
    "confirmCode": {
      "buttonLabel": "Kinnita"
    },
    "resendEmail": {
      "buttonLabel": "Saada uuesti"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Saada uuesti {seconds} sekundi pärast"
    }
  },
  "fi-FI": {
    "unlockWith": {
      "label": "Syötä ja vahvista vastaanotettu todennuskoodi."
    },
    "notification": {
      "emailSent": {
        "title": "Onnistunut toiminta",
        "message": "Todennuskoodi lähetettiin sähköpostitse. Sitä voidaan käyttää vain kerran ja rajoitetun ajan rekisteröintiä varten."
      },
      "emailOTPNotVerified": {
        "title": "Virhe",
        "message": "Todennuskoodia ei voitu vahvistaa."
      },
      "verifyEmailOTP": {
        "message": "Todennuskoodia ei voitu vahvistaa.",
        "title": "Virhe",
        "asyncError": {
          "message": "Todennuskoodia ei voitu vahvistaa.",
          "title": "Virhe"
        }
      },
      "sendEmail": {
        "message": "Todennuskoodi lähetettiin sähköpostitse. Sitä voidaan käyttää vain kerran ja rajoitetun ajan rekisteröintiä varten.",
        "title": "Onnistunut toiminta",
        "success": {
          "message": "Todennuskoodi lähetettiin sähköpostitse. Sitä voidaan käyttää vain kerran ja rajoitetun ajan rekisteröintiä varten.",
          "title": "Onnistunut toiminta"
        }
      }
    },
    "emailOTPForm": {
      "title": "Todennuskoodi sähköpostitse"
    },
    "confirmCode": {
      "buttonLabel": "Vahvista"
    },
    "resendEmail": {
      "buttonLabel": "Lähetä uudestaan"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Lähetä uudelleen {seconds} sekunnissa"
    }
  },
  "he-IL": {
    "unlockWith": {
      "label": "הזן ואשר את קוד האימות שהתקבל."
    },
    "notification": {
      "emailSent": {
        "title": "פעולה מוצלחת",
        "message": "קוד האימות נשלח בדוא＂ל. ניתן להשתמש בו רק פעם אחת ובזמן מוגבל לרישום."
      },
      "emailOTPNotVerified": {
        "title": "שְׁגִיאָה",
        "message": "לא ניתן היה לאשר את קוד האימות."
      },
      "verifyEmailOTP": {
        "message": "לא ניתן היה לאשר את קוד האימות.",
        "title": "שְׁגִיאָה",
        "asyncError": {
          "message": "לא ניתן היה לאשר את קוד האימות.",
          "title": "שְׁגִיאָה"
        }
      },
      "sendEmail": {
        "message": "קוד האימות נשלח בדוא＂ל. ניתן להשתמש בו רק פעם אחת ובזמן מוגבל לרישום.",
        "title": "פעולה מוצלחת",
        "success": {
          "message": "קוד האימות נשלח בדוא＂ל. ניתן להשתמש בו רק פעם אחת ובזמן מוגבל לרישום.",
          "title": "פעולה מוצלחת"
        }
      }
    },
    "emailOTPForm": {
      "title": "קוד אימות במייל"
    },
    "confirmCode": {
      "buttonLabel": "לְאַשֵׁר"
    },
    "resendEmail": {
      "buttonLabel": "שלח שוב"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "שלח מחדש בתוך {seconds} שניות"
    }
  },
  "ga-IE": {
    "unlockWith": {
      "label": "Cuir isteach agus deimhnigh an cód fíordheimhnithe a fuarthas."
    },
    "notification": {
      "emailSent": {
        "title": "Gníomh rathúil",
        "message": "Seoladh an cód fíordheimhnithe trí ríomhphost. Ní féidir é a úsáid ach uair amháin agus laistigh de thréimhse teoranta ama le haghaidh clárúcháin."
      },
      "emailOTPNotVerified": {
        "title": "Earráid",
        "message": "Níorbh fhéidir an cód fíordheimhnithe a dhearbhú."
      },
      "verifyEmailOTP": {
        "message": "Níorbh fhéidir an cód fíordheimhnithe a dhearbhú.",
        "title": "Earráid",
        "asyncError": {
          "message": "Níorbh fhéidir an cód fíordheimhnithe a dhearbhú.",
          "title": "Earráid"
        }
      },
      "sendEmail": {
        "message": "Seoladh an cód fíordheimhnithe trí ríomhphost. Ní féidir é a úsáid ach uair amháin agus laistigh de thréimhse teoranta ama le haghaidh clárúcháin.",
        "title": "Gníomh rathúil",
        "success": {
          "message": "Seoladh an cód fíordheimhnithe trí ríomhphost. Ní féidir é a úsáid ach uair amháin agus laistigh de thréimhse teoranta ama le haghaidh clárúcháin.",
          "title": "Gníomh rathúil"
        }
      }
    },
    "emailOTPForm": {
      "title": "Cód fíordheimhnithe trí ríomhphost"
    },
    "confirmCode": {
      "buttonLabel": "Deimhnigh"
    },
    "resendEmail": {
      "buttonLabel": "Seol arís"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Athsheol i gceann {seconds} soicind"
    }
  },
  "ja-JP": {
    "unlockWith": {
      "label": "受信した認証コードを入力して確認します。"
    },
    "notification": {
      "emailSent": {
        "title": "成功したアクション",
        "message": "認証コードはメールで送信されました。 1回のみ利用可能で、登録の制限時間内に利用できます。"
      },
      "emailOTPNotVerified": {
        "title": "エラー",
        "message": "認証コードが確認できませんでした。"
      },
      "verifyEmailOTP": {
        "message": "認証コードが確認できませんでした。",
        "title": "エラー",
        "asyncError": {
          "message": "認証コードが確認できませんでした。",
          "title": "エラー"
        }
      },
      "sendEmail": {
        "message": "認証コードはメールで送信されました。 1回のみ利用可能で、登録の制限時間内に利用できます。",
        "title": "成功したアクション",
        "success": {
          "message": "認証コードはメールで送信されました。 1回のみ利用可能で、登録の制限時間内に利用できます。",
          "title": "成功したアクション"
        }
      }
    },
    "emailOTPForm": {
      "title": "メールによる認証コード"
    },
    "confirmCode": {
      "buttonLabel": "確認"
    },
    "resendEmail": {
      "buttonLabel": "もう一度送信"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "{seconds}秒で再送信"
    }
  },
  "hr-HR": {
    "unlockWith": {
      "label": "Unesite i potvrdite primljeni autentifikacijski kod."
    },
    "notification": {
      "emailSent": {
        "title": "Uspješna akcija",
        "message": "Kod za provjeru autentičnosti poslan je e-poštom. Može se koristiti samo jednom i unutar ograničenog vremena za registraciju."
      },
      "emailOTPNotVerified": {
        "title": "Pogreška",
        "message": "Nije moguće potvrditi autentifikacijski kod."
      },
      "verifyEmailOTP": {
        "message": "Nije moguće potvrditi autentifikacijski kod.",
        "title": "Pogreška",
        "asyncError": {
          "message": "Nije moguće potvrditi autentifikacijski kod.",
          "title": "Pogreška"
        }
      },
      "sendEmail": {
        "message": "Kod za provjeru autentičnosti poslan je e-poštom. Može se koristiti samo jednom i unutar ograničenog vremena za registraciju.",
        "title": "Uspješna akcija",
        "success": {
          "message": "Kod za provjeru autentičnosti poslan je e-poštom. Može se koristiti samo jednom i unutar ograničenog vremena za registraciju.",
          "title": "Uspješna akcija"
        }
      }
    },
    "emailOTPForm": {
      "title": "Autentifikacijski kod e-poštom"
    },
    "confirmCode": {
      "buttonLabel": "Potvrda"
    },
    "resendEmail": {
      "buttonLabel": "Pošalji opet"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Ponovno slanje u {seconds} sekundama"
    }
  },
  "lv-LV": {
    "unlockWith": {
      "label": "Ievadiet un apstipriniet saņemto autentifikācijas kodu."
    },
    "notification": {
      "emailSent": {
        "title": "Veiksmīga darbība",
        "message": "Autentifikācijas kods tika nosūtīts pa e-pastu. To var izmantot tikai vienu reizi un ierobežotā reģistrācijas laikā."
      },
      "emailOTPNotVerified": {
        "title": "Kļūda",
        "message": "Nevarēja apstiprināt autentifikācijas kodu."
      },
      "verifyEmailOTP": {
        "message": "Nevarēja apstiprināt autentifikācijas kodu.",
        "title": "Kļūda",
        "asyncError": {
          "message": "Nevarēja apstiprināt autentifikācijas kodu.",
          "title": "Kļūda"
        }
      },
      "sendEmail": {
        "message": "Autentifikācijas kods tika nosūtīts pa e-pastu. To var izmantot tikai vienu reizi un ierobežotā reģistrācijas laikā.",
        "title": "Veiksmīga darbība",
        "success": {
          "message": "Autentifikācijas kods tika nosūtīts pa e-pastu. To var izmantot tikai vienu reizi un ierobežotā reģistrācijas laikā.",
          "title": "Veiksmīga darbība"
        }
      }
    },
    "emailOTPForm": {
      "title": "Autentifikācijas kods pa e-pastu"
    },
    "confirmCode": {
      "buttonLabel": "Apstiprināt"
    },
    "resendEmail": {
      "buttonLabel": "Sūtīt vēlreiz"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Atkārtoti nosūtīt pēc {seconds} sek."
    }
  },
  "lt-LT": {
    "unlockWith": {
      "label": "Įveskite ir patvirtinkite gautą autentifikavimo kodą."
    },
    "notification": {
      "emailSent": {
        "title": "Sėkmingas veiksmas",
        "message": "Autentifikavimo kodas buvo išsiųstas el. paštu. Jį galima naudoti tik vieną kartą ir per ribotą registracijos laiką."
      },
      "emailOTPNotVerified": {
        "title": "Klaida",
        "message": "Nepavyko patvirtinti autentifikavimo kodo."
      },
      "verifyEmailOTP": {
        "message": "Nepavyko patvirtinti autentifikavimo kodo.",
        "title": "Klaida",
        "asyncError": {
          "message": "Nepavyko patvirtinti autentifikavimo kodo.",
          "title": "Klaida"
        }
      },
      "sendEmail": {
        "message": "Autentifikavimo kodas buvo išsiųstas el. paštu. Jį galima naudoti tik vieną kartą ir per ribotą registracijos laiką.",
        "title": "Sėkmingas veiksmas",
        "success": {
          "message": "Autentifikavimo kodas buvo išsiųstas el. paštu. Jį galima naudoti tik vieną kartą ir per ribotą registracijos laiką.",
          "title": "Sėkmingas veiksmas"
        }
      }
    },
    "emailOTPForm": {
      "title": "Autentifikavimo kodas el. Paštu"
    },
    "confirmCode": {
      "buttonLabel": "Patvirtinti"
    },
    "resendEmail": {
      "buttonLabel": "Siųsti dar kartą"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Iš naujo išsiųsti po {seconds} sek."
    }
  },
  "pt-PT": {
    "unlockWith": {
      "label": "Digite e confirme o código de autenticação recebido."
    },
    "notification": {
      "emailSent": {
        "title": "Ação bem-sucedida",
        "message": "O código de autenticação foi enviado por e-mail. Ele só pode ser usado uma vez e dentro de um tempo limitado para registro."
      },
      "emailOTPNotVerified": {
        "title": "Erro",
        "message": "Não foi possível confirmar o código de autenticação."
      },
      "verifyEmailOTP": {
        "message": "Não foi possível confirmar o código de autenticação.",
        "title": "Erro",
        "asyncError": {
          "message": "Não foi possível confirmar o código de autenticação.",
          "title": "Erro"
        }
      },
      "sendEmail": {
        "message": "O código de autenticação foi enviado por e-mail. Ele só pode ser usado uma vez e dentro de um tempo limitado para registro.",
        "title": "Ação bem-sucedida",
        "success": {
          "message": "O código de autenticação foi enviado por e-mail. Ele só pode ser usado uma vez e dentro de um tempo limitado para registro.",
          "title": "Ação bem-sucedida"
        }
      }
    },
    "emailOTPForm": {
      "title": "Código de autenticação por email"
    },
    "confirmCode": {
      "buttonLabel": "Confirmar"
    },
    "resendEmail": {
      "buttonLabel": "Envie novamente"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Reenviar em {seconds} segundos"
    }
  },
  "ro-RO": {
    "unlockWith": {
      "label": "Introduceți și confirmați codul de autentificare primit."
    },
    "notification": {
      "emailSent": {
        "title": "Acțiune reușită",
        "message": "Codul de autentificare a fost trimis prin e-mail. Poate fi folosit o singură dată și într-un timp limitat pentru înregistrare."
      },
      "emailOTPNotVerified": {
        "title": "Eroare",
        "message": "Codul de autentificare nu a putut fi confirmat."
      },
      "verifyEmailOTP": {
        "message": "Codul de autentificare nu a putut fi confirmat.",
        "title": "Eroare",
        "asyncError": {
          "message": "Codul de autentificare nu a putut fi confirmat.",
          "title": "Eroare"
        }
      },
      "sendEmail": {
        "message": "Codul de autentificare a fost trimis prin e-mail. Poate fi folosit o singură dată și într-un timp limitat pentru înregistrare.",
        "title": "Acțiune reușită",
        "success": {
          "message": "Codul de autentificare a fost trimis prin e-mail. Poate fi folosit o singură dată și într-un timp limitat pentru înregistrare.",
          "title": "Acțiune reușită"
        }
      }
    },
    "emailOTPForm": {
      "title": "Cod de autentificare prin e-mail"
    },
    "confirmCode": {
      "buttonLabel": "Confirmare"
    },
    "resendEmail": {
      "buttonLabel": "Trimite din nou"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Retrimitere în {seconds} secunde"
    }
  },
  "ru-RU": {
    "unlockWith": {
      "label": "Введите и подтвердите полученный код аутентификации."
    },
    "notification": {
      "emailSent": {
        "title": "Успешное действие",
        "message": "Код аутентификации был отправлен по электронной почте. Его можно использовать только один раз и в течение ограниченного времени регистрации."
      },
      "emailOTPNotVerified": {
        "title": "Ошибка",
        "message": "Код аутентификации не подтвержден."
      },
      "verifyEmailOTP": {
        "message": "Код аутентификации не подтвержден.",
        "title": "Ошибка",
        "asyncError": {
          "message": "Код аутентификации не подтвержден.",
          "title": "Ошибка"
        }
      },
      "sendEmail": {
        "message": "Код аутентификации был отправлен по электронной почте. Его можно использовать только один раз и в течение ограниченного времени регистрации.",
        "title": "Успешное действие",
        "success": {
          "message": "Код аутентификации был отправлен по электронной почте. Его можно использовать только один раз и в течение ограниченного времени регистрации.",
          "title": "Успешное действие"
        }
      }
    },
    "emailOTPForm": {
      "title": "Код аутентификации по электронной почте"
    },
    "confirmCode": {
      "buttonLabel": "Подтвердить"
    },
    "resendEmail": {
      "buttonLabel": "Отправь еще раз"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Отправить повторно через {seconds} секунд"
    }
  },
  "sv-SE": {
    "unlockWith": {
      "label": "Ange och bekräfta den mottagna autentiseringskoden."
    },
    "notification": {
      "emailSent": {
        "title": "Åtgärd utförd",
        "message": "Autentiseringskoden skickades via e-post. Den kan endast användas en gång och inom en begränsad tid för registrering."
      },
      "emailOTPNotVerified": {
        "title": "Fel",
        "message": "Autentiseringskoden kunde inte bekräftas."
      },
      "verifyEmailOTP": {
        "message": "Autentiseringskoden kunde inte bekräftas.",
        "title": "Fel",
        "asyncError": {
          "message": "Autentiseringskoden kunde inte bekräftas.",
          "title": "Fel"
        }
      },
      "sendEmail": {
        "message": "Autentiseringskoden skickades via e-post. Den kan endast användas en gång och inom en begränsad tid för registrering.",
        "title": "Åtgärd utförd",
        "success": {
          "message": "Autentiseringskoden skickades via e-post. Den kan endast användas en gång och inom en begränsad tid för registrering.",
          "title": "Åtgärd utförd"
        }
      }
    },
    "emailOTPForm": {
      "title": "Autentiseringskod via e-post"
    },
    "confirmCode": {
      "buttonLabel": "Bekräfta"
    },
    "resendEmail": {
      "buttonLabel": "Skicka igen"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Skicka igen om {seconds} sekunder"
    }
  },
  "sk-SK": {
    "unlockWith": {
      "label": "Zadajte a potvrďte prijatý overovací kód."
    },
    "notification": {
      "emailSent": {
        "title": "Úspešná akcia",
        "message": "Overovací kód bol odoslaný e-mailom. Môže byť použitý iba raz a v obmedzenom čase na registráciu."
      },
      "emailOTPNotVerified": {
        "title": "Chyba",
        "message": "Overovací kód nebolo možné potvrdiť."
      },
      "verifyEmailOTP": {
        "message": "Overovací kód nebolo možné potvrdiť.",
        "title": "Chyba",
        "asyncError": {
          "message": "Overovací kód nebolo možné potvrdiť.",
          "title": "Chyba"
        }
      },
      "sendEmail": {
        "message": "Overovací kód bol odoslaný e-mailom. Môže byť použitý iba raz a v obmedzenom čase na registráciu.",
        "title": "Úspešná akcia",
        "success": {
          "message": "Overovací kód bol odoslaný e-mailom. Môže byť použitý iba raz a v obmedzenom čase na registráciu.",
          "title": "Úspešná akcia"
        }
      }
    },
    "emailOTPForm": {
      "title": "Autentifikačný kód e-mailom"
    },
    "confirmCode": {
      "buttonLabel": "Potvrdiť"
    },
    "resendEmail": {
      "buttonLabel": "Poslať znova"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Opäť odoslať o {seconds} sekúnd"
    }
  },
  "cs-CZ": {
    "unlockWith": {
      "label": "Zadejte a potvrďte přijatý ověřovací kód."
    },
    "notification": {
      "emailSent": {
        "title": "Úspěšná akce",
        "message": "Ověřovací kód byl zaslán e-mailem. Lze jej použít pouze jednou a během omezené doby pro registraci."
      },
      "emailOTPNotVerified": {
        "title": "Chyba",
        "message": "Nelze potvrdit ověřovací kód."
      },
      "verifyEmailOTP": {
        "message": "Nelze potvrdit ověřovací kód.",
        "title": "Chyba",
        "asyncError": {
          "message": "Nelze potvrdit ověřovací kód.",
          "title": "Chyba"
        }
      },
      "sendEmail": {
        "message": "Ověřovací kód byl zaslán e-mailem. Lze jej použít pouze jednou a během omezené doby pro registraci.",
        "title": "Úspěšná akce",
        "success": {
          "message": "Ověřovací kód byl zaslán e-mailem. Lze jej použít pouze jednou a během omezené doby pro registraci.",
          "title": "Úspěšná akce"
        }
      }
    },
    "emailOTPForm": {
      "title": "Ověřovací kód e-mailem"
    },
    "confirmCode": {
      "buttonLabel": "Potvrdit"
    },
    "resendEmail": {
      "buttonLabel": "Poslat znovu"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Odeslat znovu za {seconds} s."
    }
  },
  "hu-HU": {
    "unlockWith": {
      "label": "Írja be és erősítse meg a kapott hitelesítési kódot."
    },
    "notification": {
      "emailSent": {
        "title": "Sikeres művelet",
        "message": "A hitelesítési kódot e-mailben küldték el. Csak egyszer és korlátozott ideig használható fel regisztrációhoz."
      },
      "emailOTPNotVerified": {
        "title": "Hiba",
        "message": "A hitelesítő kódot nem sikerült megerősíteni."
      },
      "verifyEmailOTP": {
        "message": "A hitelesítő kódot nem sikerült megerősíteni.",
        "title": "Hiba",
        "asyncError": {
          "message": "A hitelesítő kódot nem sikerült megerősíteni.",
          "title": "Hiba"
        }
      },
      "sendEmail": {
        "message": "A hitelesítési kódot e-mailben küldték el. Csak egyszer és korlátozott ideig használható fel regisztrációhoz.",
        "title": "Sikeres művelet",
        "success": {
          "message": "A hitelesítési kódot e-mailben küldték el. Csak egyszer és korlátozott ideig használható fel regisztrációhoz.",
          "title": "Sikeres művelet"
        }
      }
    },
    "emailOTPForm": {
      "title": "Hitelesítési kód e-mailben"
    },
    "confirmCode": {
      "buttonLabel": "Megerősít"
    },
    "resendEmail": {
      "buttonLabel": "Küldd újra"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Újraküldés {seconds} másodperc múlva"
    }
  },
  "fr-BE": {
    "unlockWith": {
      "label": "Entrez et confirmez le code d'authentification reçu."
    },
    "notification": {
      "emailSent": {
        "title": "Action réussie",
        "message": "Le code d'authentification a été envoyé par e-mail. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour l'enregistrement."
      },
      "emailOTPNotVerified": {
        "title": "Erreur",
        "message": "Le code d'authentification n'a pas pu être confirmé."
      },
      "verifyEmailOTP": {
        "message": "Le code d'authentification n'a pas pu être confirmé.",
        "title": "Erreur",
        "asyncError": {
          "message": "Le code d'authentification n'a pas pu être confirmé.",
          "title": "Erreur"
        }
      },
      "sendEmail": {
        "message": "Le code d'authentification a été envoyé par e-mail. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour l'enregistrement.",
        "title": "Action réussie",
        "success": {
          "message": "Le code d'authentification a été envoyé par e-mail. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour l'enregistrement.",
          "title": "Action réussie"
        }
      }
    },
    "emailOTPForm": {
      "title": "Code d'authentification par email"
    },
    "confirmCode": {
      "buttonLabel": "Confirmer"
    },
    "resendEmail": {
      "buttonLabel": "Envoyer à nouveau"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Renvoyer dans {seconds} secondes"
    }
  },
  "fr-LU": {
    "unlockWith": {
      "label": "Entrez et confirmez le code d'authentification reçu."
    },
    "notification": {
      "emailSent": {
        "title": "Action réussie",
        "message": "Le code d'authentification a été envoyé par e-mail. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour l'enregistrement."
      },
      "emailOTPNotVerified": {
        "title": "Erreur",
        "message": "Le code d'authentification n'a pas pu être confirmé."
      },
      "verifyEmailOTP": {
        "message": "Le code d'authentification n'a pas pu être confirmé.",
        "title": "Erreur",
        "asyncError": {
          "message": "Le code d'authentification n'a pas pu être confirmé.",
          "title": "Erreur"
        }
      },
      "sendEmail": {
        "message": "Le code d'authentification a été envoyé par e-mail. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour l'enregistrement.",
        "title": "Action réussie",
        "success": {
          "message": "Le code d'authentification a été envoyé par e-mail. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour l'enregistrement.",
          "title": "Action réussie"
        }
      }
    },
    "emailOTPForm": {
      "title": "Code d'authentification par email"
    },
    "confirmCode": {
      "buttonLabel": "Confirmer"
    },
    "resendEmail": {
      "buttonLabel": "Envoyer à nouveau"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Renvoyer dans {seconds} secondes"
    }
  },
  "mt-MT": {
    "unlockWith": {
      "label": "Daħħal u kkonferma l-kodiċi ta 'awtentikazzjoni riċevut."
    },
    "notification": {
      "emailSent": {
        "title": "Azzjoni ta' suċċess",
        "message": "Il-kodiċi ta' awtentikazzjoni ntbagħat permezz ta' email. Jista' jintuża darba biss u fi żmien limitat għar-reġistrazzjoni."
      },
      "emailOTPNotVerified": {
        "title": "Żball",
        "message": "Il-kodiċi ta' awtentikazzjoni ma setax jiġi kkonfermat."
      },
      "verifyEmailOTP": {
        "message": "Il-kodiċi ta' awtentikazzjoni ma setax jiġi kkonfermat.",
        "title": "Żball",
        "asyncError": {
          "message": "Il-kodiċi ta' awtentikazzjoni ma setax jiġi kkonfermat.",
          "title": "Żball"
        }
      },
      "sendEmail": {
        "message": "Il-kodiċi ta' awtentikazzjoni ntbagħat permezz ta' email. Jista' jintuża darba biss u fi żmien limitat għar-reġistrazzjoni.",
        "title": "Azzjoni ta' suċċess",
        "success": {
          "message": "Il-kodiċi ta' awtentikazzjoni ntbagħat permezz ta' email. Jista' jintuża darba biss u fi żmien limitat għar-reġistrazzjoni.",
          "title": "Azzjoni ta' suċċess"
        }
      }
    },
    "emailOTPForm": {
      "title": "Kodiċi ta 'awtentikazzjoni bl-email"
    },
    "confirmCode": {
      "buttonLabel": "Ikkonferma"
    },
    "resendEmail": {
      "buttonLabel": "Ibgħat mill-ġdid"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Erġa' ibgħat fi {seconds} sekondi"
    }
  },
  "sl-SI": {
    "unlockWith": {
      "label": "Vnesite in potrdite prejeto kodo za preverjanje pristnosti."
    },
    "notification": {
      "emailSent": {
        "title": "Uspešno opravljeno",
        "message": "Koda za preverjanje pristnosti je bila poslana po e-pošti. Uporablja se lahko samo enkrat in v omejenem času za registracijo."
      },
      "emailOTPNotVerified": {
        "title": "Napaka",
        "message": "Kode za preverjanje pristnosti ni bilo mogoče potrditi."
      },
      "verifyEmailOTP": {
        "message": "Kode za preverjanje pristnosti ni bilo mogoče potrditi.",
        "title": "Napaka",
        "asyncError": {
          "message": "Kode za preverjanje pristnosti ni bilo mogoče potrditi.",
          "title": "Napaka"
        }
      },
      "sendEmail": {
        "message": "Koda za preverjanje pristnosti je bila poslana po e-pošti. Uporablja se lahko samo enkrat in v omejenem času za registracijo.",
        "title": "Uspešno opravljeno",
        "success": {
          "message": "Koda za preverjanje pristnosti je bila poslana po e-pošti. Uporablja se lahko samo enkrat in v omejenem času za registracijo.",
          "title": "Uspešno opravljeno"
        }
      }
    },
    "emailOTPForm": {
      "title": "Koda za preverjanje pristnosti po e-pošti"
    },
    "confirmCode": {
      "buttonLabel": "Potrdi"
    },
    "resendEmail": {
      "buttonLabel": "Pošlji ponovno"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Ponovno pošlji v {seconds} sekundah"
    }
  },
  "tr-TR": {
    "unlockWith": {
      "label": "Alınan doğrulama kodunu girin ve onaylayın."
    },
    "notification": {
      "emailSent": {
        "title": "Başarılı eylem",
        "message": "Kimlik doğrulama kodu e-posta yoluyla gönderildi. Kayıt için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."
      },
      "emailOTPNotVerified": {
        "title": "Hata",
        "message": "Kimlik doğrulama kodu onaylanamadı."
      },
      "verifyEmailOTP": {
        "message": "Kimlik doğrulama kodu onaylanamadı.",
        "title": "Hata",
        "asyncError": {
          "message": "Kimlik doğrulama kodu onaylanamadı.",
          "title": "Hata"
        }
      },
      "sendEmail": {
        "message": "Kimlik doğrulama kodu e-posta yoluyla gönderildi. Kayıt için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir.",
        "title": "Başarılı eylem",
        "success": {
          "message": "Kimlik doğrulama kodu e-posta yoluyla gönderildi. Kayıt için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir.",
          "title": "Başarılı eylem"
        }
      }
    },
    "emailOTPForm": {
      "title": "E-posta ile kimlik doğrulama kodu"
    },
    "confirmCode": {
      "buttonLabel": "Onayla"
    },
    "resendEmail": {
      "buttonLabel": "Tekrar gönderin"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "{seconds} saniye içinde yeniden gönder"
    }
  },
  "tr-CY": {
    "unlockWith": {
      "label": "Alınan doğrulama kodunu girin ve onaylayın."
    },
    "notification": {
      "emailSent": {
        "title": "Başarılı eylem",
        "message": "Kimlik doğrulama kodu e-posta yoluyla gönderildi. Kayıt için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."
      },
      "emailOTPNotVerified": {
        "title": "Hata",
        "message": "Kimlik doğrulama kodu onaylanamadı."
      },
      "verifyEmailOTP": {
        "message": "Kimlik doğrulama kodu onaylanamadı.",
        "title": "Hata",
        "asyncError": {
          "message": "Kimlik doğrulama kodu onaylanamadı.",
          "title": "Hata"
        }
      },
      "sendEmail": {
        "message": "Kimlik doğrulama kodu e-posta yoluyla gönderildi. Kayıt için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir.",
        "title": "Başarılı eylem",
        "success": {
          "message": "Kimlik doğrulama kodu e-posta yoluyla gönderildi. Kayıt için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir.",
          "title": "Başarılı eylem"
        }
      }
    },
    "emailOTPForm": {
      "title": "E-posta ile kimlik doğrulama kodu"
    },
    "confirmCode": {
      "buttonLabel": "Onayla"
    },
    "resendEmail": {
      "buttonLabel": "Tekrar gönderin"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "{seconds} saniye içinde yeniden gönder"
    }
  },
  "uk-UA": {
    "unlockWith": {
      "label": "Введіть і підтвердьте отриманий код автентифікації."
    },
    "notification": {
      "emailSent": {
        "title": "Успішна акція",
        "message": "Код автентифікації надіслано електронною поштою. Його можна використовувати лише один раз і протягом обмеженого часу для реєстрації."
      },
      "emailOTPNotVerified": {
        "title": "Помилка",
        "message": "Не вдалося підтвердити код автентифікації."
      },
      "verifyEmailOTP": {
        "message": "Не вдалося підтвердити код автентифікації.",
        "title": "Помилка",
        "asyncError": {
          "message": "Не вдалося підтвердити код автентифікації.",
          "title": "Помилка"
        }
      },
      "sendEmail": {
        "message": "Код автентифікації надіслано електронною поштою. Його можна використовувати лише один раз і протягом обмеженого часу для реєстрації.",
        "title": "Успішна акція",
        "success": {
          "message": "Код автентифікації надіслано електронною поштою. Його можна використовувати лише один раз і протягом обмеженого часу для реєстрації.",
          "title": "Успішна акція"
        }
      }
    },
    "emailOTPForm": {
      "title": "Код автентифікації електронною поштою"
    },
    "confirmCode": {
      "buttonLabel": "Підтвердити"
    },
    "resendEmail": {
      "buttonLabel": "Надіслати знову"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "Надіслати повторно через {seconds} секунд"
    }
  },
  "zh-CN": {
    "unlockWith": {
      "label": "输入并确认收到的验证码。"
    },
    "notification": {
      "emailSent": {
        "title": "操作成功",
        "message": "验证码是通过电子邮件发送的。 注册后限时使用一次。"
      },
      "emailOTPNotVerified": {
        "title": "错误",
        "message": "无法确认验证码。"
      },
      "verifyEmailOTP": {
        "message": "无法确认验证码。",
        "title": "错误",
        "asyncError": {
          "message": "无法确认验证码。",
          "title": "错误"
        }
      },
      "sendEmail": {
        "message": "验证码是通过电子邮件发送的。 注册后限时使用一次。",
        "title": "操作成功",
        "success": {
          "message": "验证码是通过电子邮件发送的。 注册后限时使用一次。",
          "title": "操作成功"
        }
      }
    },
    "emailOTPForm": {
      "title": "通过电子邮件验证代码"
    },
    "confirmCode": {
      "buttonLabel": "确认"
    },
    "resendEmail": {
      "buttonLabel": "重新发送"
    },
    "resendEmailInSeconds": {
      "buttonLabel": "在{seconds}内重新发送"
    }
  }
}
</i18n>
